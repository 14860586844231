/* global RequestInit */
export function fetchUser({ authenticationUrl }) {
    return fetch(authenticationUrl, {
        credentials: 'include',
        method: 'GET'
    }).then(response => response.json());
}
export function fetchSession({ authenticationUrl }) {
    return fetch(`${authenticationUrl}/session`, {
        credentials: 'include',
        method: 'GET'
    }).then(response => response.json());
}
export function fetchUserProfileInfo({ authenticationUrl, jwt }) {
    const options = generateFetchOptions({ method: 'GET', jwt });
    return fetch(`${authenticationUrl}/profile`, options).then(response => response.json());
}
export function fetchNotifications({ authenticationUrl, jwt }) {
    const options = generateFetchOptions({ method: 'GET', jwt });
    return fetch(`${authenticationUrl}/profile/notifications?sortDir=desc&size=5`, options).then(response => response.json());
}
export function fetchNotificationSummary({ authenticationUrl, jwt }) {
    const options = generateFetchOptions({ method: 'GET', jwt });
    return fetch(`${authenticationUrl}/profile/notifications/summary`, options).then(response => response.json());
}
export function dismissNotifications({ authenticationUrl, jwt }) {
    const now = new Date(Date.now()).toISOString();
    const body = { startDate: now };
    const options = generateFetchOptions({ method: 'PUT', jwt, body: JSON.stringify(body) });
    return fetch(`${authenticationUrl}/profile/notifications/dismiss`, options).then(response => response.json());
}
function generateFetchOptions({ method, jwt }) {
    const headers = new Headers();
    if (jwt) {
        headers.set('authorization', `Bearer ${jwt}`);
    }
    const options = {
        method,
        credentials: 'include',
        headers
    };
    return options;
}
