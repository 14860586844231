// Supported ISO 639.1 Languages
export const ISO_639_1_ENGLISH = 'en';
export const ISO_639_1_CHINESE = 'zh';
export const ISO_639_1_CZECH = 'cs';
export const ISO_639_1_FRENCH = 'fr';
export const ISO_639_1_GERMAN = 'de';
export const ISO_639_1_ITALIAN = 'it';
export const ISO_639_1_JAPANESE = 'ja';
export const ISO_639_1_KOREAN = 'ko';
export const ISO_639_1_POLISH = 'pl';
export const ISO_639_1_PORTUGUESE = 'pt';
export const ISO_639_1_RUSSIAN = 'ru';
export const ISO_639_1_SPANISH = 'es';
export const ISO_639_1_HUNGARIAN = 'hu';
// Supported ISO-3166 Country Codes
export const ISO_3166_USA = 'US';
export const ISO_3166_GERMANY = 'DE';
export const ISO_3166_CHINA = 'CN';
export const ISO_3166_JAPAN = 'JP';
export const ISO_3166_CZECH_REPUBLIC = 'CZ';
export const ISO_3166_SPAIN = 'ES';
export const ISO_3166_FRANCE = 'FR';
export const ISO_3166_ITALY = 'IT';
export const ISO_3166_KOREA = 'KR';
export const ISO_3166_RUSSIA = 'RU';
export const ISO_3166_POLAND = 'PL';
export const ISO_3166_BRAZIL = 'BR';
export const ISO_3166_TAIWAN = 'TW';
export const ISO_3166_HUNGARY = 'HU';
export const ISO_3166_BELARUS = 'BY';
// Supported Locales
export const LOCALE_ENGLISH_USA = `${ISO_639_1_ENGLISH}-${ISO_3166_USA}`;
export const LOCALE_GERMAN_GERMANY = `${ISO_639_1_GERMAN}-${ISO_3166_GERMANY}`;
export const LOCALE_CHINESE_CHINA = `${ISO_639_1_CHINESE}-${ISO_3166_CHINA}`;
export const LOCALE_JAPANESE_JAPAN = `${ISO_639_1_JAPANESE}-${ISO_3166_JAPAN}`;
export const LOCALE_CZECH_CZECH_REPUBLIC = `${ISO_639_1_CZECH}-${ISO_3166_CZECH_REPUBLIC}`;
export const LOCALE_SPANISH_SPAIN = `${ISO_639_1_SPANISH}-${ISO_3166_SPAIN}`;
export const LOCALE_FRENCH_FRANCE = `${ISO_639_1_FRENCH}-${ISO_3166_FRANCE}`;
export const LOCALE_ITALIAN_ITALY = `${ISO_639_1_ITALIAN}-${ISO_3166_ITALY}`;
export const LOCALE_KOREAN_KOREA = `${ISO_639_1_KOREAN}-${ISO_3166_KOREA}`;
export const LOCALE_RUSSIAN_RUSSIA = `${ISO_639_1_RUSSIAN}-${ISO_3166_RUSSIA}`;
export const LOCALE_POLISH_POLAND = `${ISO_639_1_POLISH}-${ISO_3166_POLAND}`;
export const LOCALE_PORTUGUESE_BRAZIL = `${ISO_639_1_PORTUGUESE}-${ISO_3166_BRAZIL}`;
export const LOCALE_CHINESE_TAIWAN = `${ISO_639_1_CHINESE}-${ISO_3166_TAIWAN}`;
export const LOCALE_HUNGARIAN_HUNGARY = `${ISO_639_1_HUNGARIAN}-${ISO_3166_HUNGARY}`;
export const DEFAULT_LOCALE_CODE = LOCALE_ENGLISH_USA;
export const LOCALES = [
    {
        code: LOCALE_ENGLISH_USA,
        name: 'U.S. English',
        nativeLanguageName: 'English',
        fallbackCode: null,
        localeLanguage: 'EN'
    },
    {
        code: LOCALE_GERMAN_GERMANY,
        name: 'German (Germany)',
        nativeLanguageName: 'Deutsch',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'DE'
    },
    {
        code: LOCALE_CHINESE_CHINA,
        name: 'Chinese (Simplified, China)',
        nativeLanguageName: '简体中文',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'ZH'
    },
    {
        code: LOCALE_JAPANESE_JAPAN,
        name: 'Japanese (Japan)',
        nativeLanguageName: '日本語',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'JA'
    },
    {
        code: LOCALE_CZECH_CZECH_REPUBLIC,
        name: 'Czech (Czech Republic)',
        nativeLanguageName: 'Čeština',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'CS'
    },
    {
        code: LOCALE_SPANISH_SPAIN,
        name: 'Spanish (Spain)',
        nativeLanguageName: 'Español',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'ES'
    },
    {
        code: LOCALE_FRENCH_FRANCE,
        name: 'French (France)',
        nativeLanguageName: 'Français',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'FR'
    },
    {
        code: LOCALE_ITALIAN_ITALY,
        name: 'Italian (Italy)',
        nativeLanguageName: 'Italiano',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'IT'
    },
    {
        code: LOCALE_KOREAN_KOREA,
        name: 'Korean (South Korea)',
        nativeLanguageName: '한국어',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'KO'
    },
    {
        code: LOCALE_RUSSIAN_RUSSIA,
        name: 'Russian (Russia)',
        nativeLanguageName: 'Русский',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'RU'
    },
    {
        code: LOCALE_POLISH_POLAND,
        name: 'Polish (Poland)',
        nativeLanguageName: 'Polski',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'PL'
    },
    {
        code: LOCALE_PORTUGUESE_BRAZIL,
        name: 'Portuguese (Brazil)',
        nativeLanguageName: 'Português (Brasil)',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'PT'
    },
    {
        code: LOCALE_CHINESE_TAIWAN,
        name: 'Chinese (Traditional, Taiwan)',
        nativeLanguageName: '繁體中文',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'ZH'
    },
    {
        code: LOCALE_HUNGARIAN_HUNGARY,
        name: 'Hungarian (Hungary)',
        nativeLanguageName: 'Magyar',
        fallbackCode: LOCALE_ENGLISH_USA,
        localeLanguage: 'HU'
    }
];
/**
 * These are the countries that DEX does not support credit card payments for.
 * We will not show the Add to Cart button for any of these countries.
 * This is a temporary solution we implemented for DEX relating to the June 1st go-live. In the future, we should be getting this information directly from the DEX API.
 */
export const ECOMMERCE_UNSUPPORTED_COUNTRIES = ['CH', 'TW', 'KR', 'HR', 'MX', 'IL', 'BR', 'ZA', 'IN'];
