const SHA_SECRET_KEY = '5a293177671c57174e243fe057b140eb6cef9d50e2fd91c674131371';
/**
 * This function is used to add a secret key to the string need to be hashed. Later the combination of that string and secret key is sent
 * to the sha256 hashing function.
 *
 * @param {string} stringToHashString to get hashed using the sha256 function
 * @param {string | undefined} secretKey The secret key to add to the string before hashing. If none is provided, will use the default secret key.
 * @returns hashed string
 */
function addSecretKeyAndHash(stringToHash, secretKey = SHA_SECRET_KEY) {
    return calculateSHA256Hash(secretKey + stringToHash);
}
/**
 * Secure Hash Algorithm (SHA256)
 * https://coursesweb.net/javascript/sha256-encrypt-hash_cs
 * Original code by Angel Marin, Paul Johnston. Converted from gibberish to TypeScript by GPT.
 **/
function calculateSHA256Hash(inputString) {
    // Constants for the SHA256 algorithm
    const bitsPerCharacter = 8;
    const useUppercaseHex = 0;
    // Addition operation for 32-bit integers
    function addWords(x, y) {
        const lsw = (x & 0xffff) + (y & 0xffff);
        const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xffff);
    }
    // Circular shift operation for 32-bit integers
    function circularShift(X, n) {
        return (X >>> n) | (X << (32 - n));
    }
    // Right shift operation for 32-bit integers
    function shiftRight(X, n) {
        return X >>> n;
    }
    // Choose function for SHA256 algorithm
    function choose(x, y, z) {
        return (x & y) ^ (~x & z);
    }
    // Majority function for SHA256 algorithm
    function majority(x, y, z) {
        return (x & y) ^ (x & z) ^ (y & z);
    }
    // Sigma0 function for SHA256 algorithm
    function sigma0256(x) {
        return circularShift(x, 2) ^ circularShift(x, 13) ^ circularShift(x, 22);
    }
    // Sigma1 function for SHA256 algorithm
    function sigma1256(x) {
        return circularShift(x, 6) ^ circularShift(x, 11) ^ circularShift(x, 25);
    }
    // Gamma0 function for SHA256 algorithm
    function gamma0256(x) {
        return circularShift(x, 7) ^ circularShift(x, 18) ^ shiftRight(x, 3);
    }
    // Gamma1 function for SHA256 algorithm
    function gamma1256(x) {
        return circularShift(x, 17) ^ circularShift(x, 19) ^ shiftRight(x, 10);
    }
    // Core SHA256 algorithm processing the message blocks
    function coreSHA256(messageWords, messageLength) {
        // Constants for the SHA256 algorithm
        const constants = [
            0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5, 0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
            0xe49b69c1, 0xefbe4786, 0xfc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da, 0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x6ca6351, 0x14292967,
            0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85, 0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
            0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3, 0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2
        ];
        // Initial hash values for SHA256
        const hashValues = [0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a, 0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19];
        // Temporary array for processing message blocks
        const messageBlocks = new Array(64);
        // These variables represent the eight 32-bit words of the hash value.
        // At the beginning of processing each message block, they are initialized with the current hash values.
        // During the processing of each word within the message block, these values are updated based on various calculations, and at the end of processing the block, they are updated to the new hash values.
        let a, b, c, d, e, f, g, h;
        let T1, T2;
        // Padding the message and setting its length
        messageWords[messageLength >> 5] |= 0x80 << (24 - (messageLength % 32));
        messageWords[(((messageLength + 64) >> 9) << 4) + 15] = messageLength;
        // Processing each message block
        for (let i = 0; i < messageWords.length; i += 16) {
            a = hashValues[0];
            b = hashValues[1];
            c = hashValues[2];
            d = hashValues[3];
            e = hashValues[4];
            f = hashValues[5];
            g = hashValues[6];
            h = hashValues[7];
            // Processing each word within a message block
            for (let j = 0; j < 64; j++) {
                if (j < 16) {
                    messageBlocks[j] = messageWords[j + i];
                }
                else {
                    messageBlocks[j] = addWords(addWords(addWords(gamma1256(messageBlocks[j - 2]), messageBlocks[j - 7]), gamma0256(messageBlocks[j - 15])), messageBlocks[j - 16]);
                }
                // Calculating intermediate values T1 and T2
                T1 = addWords(addWords(addWords(addWords(h, sigma1256(e)), choose(e, f, g)), constants[j]), messageBlocks[j]);
                T2 = addWords(sigma0256(a), majority(a, b, c));
                // Updating variables for next iteration
                h = g;
                g = f;
                f = e;
                e = addWords(d, T1);
                d = c;
                c = b;
                b = a;
                a = addWords(T1, T2);
            }
            // Updating hash values after processing a message block
            hashValues[0] = addWords(a, hashValues[0]);
            hashValues[1] = addWords(b, hashValues[1]);
            hashValues[2] = addWords(c, hashValues[2]);
            hashValues[3] = addWords(d, hashValues[3]);
            hashValues[4] = addWords(e, hashValues[4]);
            hashValues[5] = addWords(f, hashValues[5]);
            hashValues[6] = addWords(g, hashValues[6]);
            hashValues[7] = addWords(h, hashValues[7]);
        }
        return hashValues;
    }
    // Converts a string to a binary array
    function stringToBinaryArray(inputString) {
        const binaryArray = [];
        const mask = (1 << bitsPerCharacter) - 1;
        for (let i = 0; i < inputString.length * bitsPerCharacter; i += bitsPerCharacter) {
            binaryArray[i >> 5] |= (inputString.charCodeAt(i / bitsPerCharacter) & mask) << (24 - (i % 32));
        }
        return binaryArray;
    }
    // Encodes a string to UTF-8 format
    function encodeUtf8(string) {
        string = string.replace(/\r\n/g, '\n');
        let utfText = '';
        // Iterating through each character in the string
        for (let n = 0; n < string.length; n++) {
            const charCode = string.charCodeAt(n);
            // Handling ASCII characters
            if (charCode < 128) {
                utfText += String.fromCharCode(charCode);
            }
            // Handling characters between 128 and 2048
            else if (charCode > 127 && charCode < 2048) {
                utfText += String.fromCharCode((charCode >> 6) | 192);
                utfText += String.fromCharCode((charCode & 63) | 128);
            }
            // Handling characters above 2048
            else {
                utfText += String.fromCharCode((charCode >> 12) | 224);
                utfText += String.fromCharCode(((charCode >> 6) & 63) | 128);
                utfText += String.fromCharCode((charCode & 63) | 128);
            }
        }
        return utfText;
    }
    // Converts a binary array to a hexadecimal string
    function binaryArrayToHex(binArray) {
        const hexTable = useUppercaseHex ? '0123456789ABCDEF' : '0123456789abcdef';
        let result = '';
        // Iterating through each element in the binary array
        for (let i = 0; i < binArray.length * 4; i++) {
            result += hexTable.charAt((binArray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) + hexTable.charAt((binArray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
        }
        return result;
    }
    // Encoding the input string in UTF-8 and calculating SHA256 hash
    inputString = encodeUtf8(inputString);
    return binaryArrayToHex(coreSHA256(stringToBinaryArray(inputString), inputString.length * bitsPerCharacter));
}
export default addSecretKeyAndHash;
