import { getCookieValue, setCookie } from './utils/cookies';
import { getMiddlewareAPIURL } from './constants/api_urls';
import { PROACTIVE_COOKIE_NAME } from '../../universal-components/src/LiveChat/chatConstants';

/**
 * Maximum time-out for the live chat API calls.
 */
const FETCH_TIMEOUT = 10000;

/**
 * Check if the proactive cookie is set on the customers machine. The function loads the cookies and scans the list
 * for the proactive chat cookie defined in constants.
 *
 * @returns {boolean} indicating if the proactive cookie has been set, or not.
 */
export const isProactiveCookieWritten = () => {
  return typeof getCookieValue(PROACTIVE_COOKIE_NAME) !== 'undefined';
};

/**
 * Mark the proactive chat as being resolved so that it is suppressed for a number of days. Function should be called when
 * the user receives the proactive chat so that it can be suppressed for a number of days (varies depending on how proactive
 * chat was closed).
 *
 * @param {number} days number of days to suppress proactive chat for.
 * @param {boolean} devmode indicates if we're in devmode so that we can skip checking for cookie consent.
 */
export const markProactiveChatResolved = (days, devmode = false) => {
  let d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);

  !devmode &&
    window.cookieMonster.once('fnct', () => {
      setCookie(PROACTIVE_COOKIE_NAME, '', {
        expires: d,
        domain: '.siemens.com',
        path: '/'
      });
    });

  if (devmode) {
    setCookie(PROACTIVE_COOKIE_NAME, '', {
      expires: d,
      // Will use the hostname for the domain
      path: '/'
    });
  }
};

/**
 * Pulls the hours of operation configuration (JSON) via the DISW Middleware API.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @returns {Promise} Promise representing the hours of operation data as loaded from the DEX middleware
 *
 */
export const loadHoursOfOperation = async env => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), FETCH_TIMEOUT);

  const apiUrl = `${getMiddlewareAPIURL(env)}/livechat/schedule/false/chatbox/global`;

  return fetch(apiUrl, {
    signal: controller.signal
  })
    .then(response => {
      return response.ok ? response.json() : undefined;
    })
    .catch(error => {
      // Throw the error so it can be caught
      throw error;
    });
};

/**
 * Retrieves the live chat configuration for a particular site pulled over API.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @returns {Promise} Promise object representing the hours of operation data as loaded from the DEX middleware
 *
 */
export const getChatConfiguration = async env => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), FETCH_TIMEOUT);

  const domainName = window.location.hostname;
  const apiUrl = `${getMiddlewareAPIURL(env)}/livechat/configuration/${domainName}`;

  return fetch(apiUrl, {
    signal: controller.signal
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      console.error(`Error with /livechat/configuration/'. API returned - '${response.status}' - with response text: '${response.statusText}'`);
      throw Error(response.statusText);
    })
    .catch(error => {
      // Throw the error so it can be caught
      throw error;
    });
};
