/**
 * Gets the contents of a base-64 encoded query string parameter and returns the decoded object.
 * If there is no query string parameter of the given name, undefined is returned.
 *
 * @param {string} name the name of the query string parameter to retrieve
 * @returns {string | undefined} the decoded value of the query string or undefined
 */
export function getDecodedQuerystringValue(name) {
    const params = new URLSearchParams(window.location.search);
    const param = params.get(name);
    try {
        return param ? atob(param) : undefined;
    }
    catch (error) {
        // logger.warn(error); // hide runtime errors
        return undefined;
    }
}
/**
 * A helper function that adds a parameter and its value to the url.
 *
 * @param {string} url - the url to add the context to.
 * @param {string} parameter -  the parameter that needs to be attached to the url.
 * @param {string} parameterValue - this is the value of the parameter passed.
 * @returns {string} string containing the url attached with the parameter and value.
 */
export function addQueryParameter(url, parameter, parameterValue) {
    if (!parameter || !parameterValue || !url) {
        return url;
    }
    try {
        const parsedUrl = new URL(url);
        if (!parsedUrl.searchParams.get(parameter)) {
            parsedUrl.searchParams.set(parameter, parameterValue);
        }
        return parsedUrl.toString();
    }
    catch (e) {
        // url is not valid
        return url;
    }
}
