import { CART_EVENT_LIST } from './constants/tracking_events';

/**
 * The Cart Analytics module is built around the Segment.io tracking schemas implemented for all of the marketing sites.
 * Any site which has eCommerce enabled must (1) have it's tracking plan enabled in Segment with the various tracking events (product added etc)
 * listed, and (2) enabled by the NGMA team to ensure the data makes its way downstream.
 *
 * Example of website tracking schema in Segment.io: https://app.segment.com/siemens-disw/protocols/tracking-plans/rs_1d3WSPN1JSxMwqc0qfSFOvMA9SE
 */

/**
 * Represents the active shopping cart data which is populated via the cart events. This is needed so that when tracking events
 * are called, we do not need to pass the entire shopping cart data every time. This is populated via the cart events being emitted.
 *
 */
let shoppingCartData;

/**
 * A mimicked empty shopping cart which we will return in the case a shopping cart could not be created.
 *
 */
const MIMICKED_EMPTY_CART = { items: [] };

/**
 * Track the cart errors which can be emitted which indicate that a shopping cart could not be loaded. The reasons include
 * not being able to initialize a shopping cart (due to a country being passed which DEX does not support) or because of a Salesforce
 * outage on the DEX side.
 *
 */
const cartErrors = [window.disw.cart?.CART_ERROR_INITIALIZING_CART, window.disw.cart?.CART_ERROR_FETCHING_CART_DATA];

/**
 * Register the analytics module, which subscribes to the shopping cart updates. Note that shopping cart events are also emitted
 * in the case of error, so we must handle the possible responses from the module (cart data returned, or an error).
 *
 */
export const registerAnalytics = () => {
  try {
    // Subscribe to cart changes so we can track the active shopping cart to simplify tracking calls.
    window.disw.cart.subscribeToCart((cartData, error) => {
      if (error) {
        // If one of our cart laoding errors is returned, set the shopping cart to empty.
        if (cartErrors.some(err => err === error)) {
          shoppingCartData = MIMICKED_EMPTY_CART;
        }
      } else {
        shoppingCartData = cartData;
      }
    });
  } catch (error) {
    console.error(`Error initializing the eCommerce analytics ${error}`);
  }
};

/**
 * We track the current shopping cart via the event manager events which are subscribed to, and emitted. There are certain
 * times though when we need to guarantee we're using the latest data for the immediate tracking event. For instance, when a product
 * is removed from the shopping cart we need to ensure the tracking event that is immediately fired is not firing before the
 * cart update event has been emitted. This is more or less a utility function to manually update the cart data.
 *
 * @param {*} cartData shopping cart data to be updated so that the analytics is guaranteed to be working from the latest data.
 */
export const registerCartUpdate = cartData => {
  shoppingCartData = cartData;
};

/**
 * Track the 'Cart Viewed' event which should be triggered whenever the customer opens the full shopping cart.
 *
 */
export const cartViewed = cartData => {
  // If shoppingCartData and cartData is empty, don't track the event
  if (!shoppingCartData && !cartData) {
    return;
  }

  // With reloading the full cart, shoppingCartData not yet being available, so assigning cartData (which is available after page load) to shoppingCartData
  if (cartData) {
    shoppingCartData = cartData;
  }

  try {
    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      products: createProductTrackingObject() || []
    };

    // Don't send cart viewed event if the shopping cart don't have products in it
    if (trackingPayload.products?.length <= 0) {
      return;
    }

    return sendAnalytics(CART_EVENT_LIST.CART_VIEWED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Cart Viewed' event : ${error}`);
  }
};

/**
 * Fire the check-out started event when the user indicates they would like to proceed to check-out.
 * Note that no parameters are required. The cart analytics is context aware and will send the current shopping cart
 * data.
 *
 */
export const checkoutStarted = () => {
  try {
    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      currency: shoppingCartData?.currency,
      discount: parseFloat(shoppingCartData?.discount),
      order_id: '',
      products: createProductTrackingObject(),
      revenue: parseFloat(shoppingCartData?.subTotal),
      ...(shoppingCartData?.promotionCodes[0] && { coupon: shoppingCartData?.promotionCodes[0] })
    };

    return sendAnalytics(CART_EVENT_LIST.CHECKOUT_STARTED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Checkout Started' event : ${error}`);
  }
};

/**
 * We track the User viewed a product's details
 *
 * @param {*} productData product user is viewing.
 */

export const productViewed = productData => {
  if (!productData) {
    return;
  }

  try {
    const trackingPayload = {
      name: productData.name,
      sku: productData.productSku,
      image_url: productData?.thumbnail,
      url: getCurrentUrl(),
      product_id: productData.productSku
    };

    return sendAnalytics(CART_EVENT_LIST.PRODUCT_VIEWED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Product Viewed' event : ${error}`);
  }
};

/**
 * We track the User clicked on a product
 *
 * @param {*} productData product user clicked.
 */
export const productClicked = productData => {
  if (!productData) {
    return;
  }

  try {
    const trackingPayload = {
      name: productData.name,
      sku: productData.sku,
      image_url: productData?.image?.url,
      url: getCurrentUrl(),
      product_id: productData.sku,
      quantity: productData?.quantity
    };

    return sendAnalytics(CART_EVENT_LIST.PRODUCT_CLICKED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Product Clicked' event : ${error}`);
  }
};

/**
 * We track the User clicked on a product
 *
 * @param {*} productData product user clicked.
 * @param {string} contentId Identifier of the list in contentful.
 */
export const productListViewed = (productData, contentId) => {
  if (!productData) {
    return;
  }
  try {
    const trackingPayload = {
      list_id: contentId,
      products: productData
    };

    return sendAnalytics(CART_EVENT_LIST.PRODUCT_LIST_VIEWED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Product List Viewed' event : ${error}`);
  }
};

/**
 * Sends a tracking event event with each item added to the cart. Handles one item at a time
 *
 * @param {Object} addedProduct the added product json
 * @param {string} promotionCode the applied promo code if it exists
 * @param {int} itemIndex the current index of the item in the cart
 * @param {int} quantity the number of items being added
 * @param {string} context a string describing the origin of the request to add, note numeric stepper passes null
 */
export const productAdded = (productPricingId, promotionCode, itemIndex = 0, quantity) => {
  try {
    // Find the product which was just added to the shopping cart by pricing option ID (not to be confused with cart item ID).
    const addedCartItem = shoppingCartData.items.find(ci => ci.product?.pricingOptions?.find(po => po.id === productPricingId));

    // Find the pricing option for the added cart item.
    const pricingOption = addedCartItem.product?.pricingOptions?.find(po => po.id === productPricingId);

    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      image_url: addedCartItem.product?.thumbnail,
      name: addedCartItem.product?.name,
      sku: addedCartItem.product?.productSku,
      price: parseFloat(pricingOption?.price),
      product_id: addedCartItem.product?.productSku,
      position: parseInt(itemIndex ?? 0, 10),
      quantity: parseInt(quantity, 10),
      url: getCurrentUrl(),
      ...(promotionCode && { coupon: promotionCode })
    };

    return sendAnalytics(CART_EVENT_LIST.PRODUCT_ADDED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Product Added' event : ${error}`);
  }
};

/**
 * Fire the tracking event for the scenario where the user has decreased the number of licenses for a particular
 * cart item. Note that while similar to {@link productRemoved} this function tracks license decreases, whereas
 * {@link productRemoved} tracks a user removing an entire cart item with all added licenses. Note that this function
 * can remove an item from the cart entirely if the quantity matches the current number of licenses added.
 *
 * @param pricingOptionId - the ID of the pricing option for the product whose licenses are being decreased.
 * @param promotionCode - the applied promotion code for the product (optional).
 * @param itemIndex - the index of the item in the shopping cart (position).
 * @param quantity - the number of licenses being removed for the pricing option.
 */
export const productDecreased = (pricingOptionId, promotionCode, itemIndex = 0, quantity) => {
  try {
    // Find the product which is being removed from the shopping cart.
    const removedCartItem = shoppingCartData.items.find(ci => ci.product?.pricingOptions?.find(po => po.id === pricingOptionId));

    // Find the pricing option for the added cart item.
    const pricingOption = removedCartItem?.product?.pricingOptions?.find(po => po.id === pricingOptionId);

    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      image_url: removedCartItem?.product?.thumbnail,
      name: removedCartItem?.product?.name,
      sku: removedCartItem?.product?.productSku,
      price: parseFloat(pricingOption?.price),
      product_id: removedCartItem?.product?.productSku,
      position: parseInt(itemIndex ?? 0, 10),
      quantity: Math.abs(parseInt(quantity, 10)),
      url: getCurrentUrl(),
      ...(promotionCode && { coupon: promotionCode })
    };

    return sendAnalytics(CART_EVENT_LIST.PRODUCT_REMOVED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Product Removed' event : ${error}`);
  }
};

/**
 * If the product is removed from the shopping cart entirely, we need to look-up the actual cart item by ID and NOT
 * the product pricing option ID (as we do in {@link productDecreased}). Note that while both are capable of removing
 * an item from the shopping cart entirely, they function differently when calling DEX in that decreasing products consumes
 * a pricing option ID whereas removing a product entirely accepts the cart item ID.
 *
 * @param {*} cartItemId the ID of the item in the shopping cart (not to be confused with the pricing option ID).
 * @param {*} promotionCode the promotion code applied to the product (if applicable)
 * @param {*} itemIndex index of the item in the shopping cart.
 * @param {*} quantity number of licenses removed from the shopping cart (one product could be added with 5 licenses).
 *
 */
export const productRemoved = (cartItemId, promotionCode, itemIndex = 0, quantity) => {
  try {
    // Find the cart item by the passed cart item ID (not to be confused with a pricing option ID).
    const removedCartItem = shoppingCartData.items.find(ci => ci.id === cartItemId);

    // Find the pricing option with autorenew true.
    const pricingOption = removedCartItem?.product?.pricingOptions?.find(po => po.autoRenew === true);

    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      image_url: removedCartItem?.product?.thumbnail,
      name: removedCartItem?.product?.name,
      sku: removedCartItem?.product?.productSku,
      price: parseFloat(pricingOption?.price),
      product_id: removedCartItem?.product?.productSku,
      position: parseInt(itemIndex ?? 0, 10),
      quantity: Math.abs(parseInt(quantity, 10)),
      url: getCurrentUrl(),
      ...(promotionCode && { coupon: promotionCode })
    };

    return sendAnalytics(CART_EVENT_LIST.PRODUCT_REMOVED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Product Removed' event : ${error}`);
  }
};

/**
 * Send a tracking event tracking event for a coupon being applied regardless of acceptance/rejection
 *
 * @param {Object} (cartId, promotionCode)  { promotionCode = string, cartId = string }
 * @returns {Promise} Promise object representing the return data from window.analytics.track()
 */
export const couponEntered = promotionCode => {
  try {
    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      coupon_id: promotionCode,
      order_id: ''
    };

    return sendAnalytics(CART_EVENT_LIST.COUPON_ENTERED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Coupon Entered' event : ${error}`);
  }
};

/**
 * Sends a tracking event event when entered promotion is accepted.
 *
 * @param {Object} cartId couponName, discount : {couponName: string, discout = number, cartID = string}
 * @returns {Promise} Promise object representing the return data from window.analytics.track()
 */
export const couponApplied = couponName => {
  try {
    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      coupon_id: couponName,
      coupon_name: couponName,
      order_id: '',
      discount: parseFloat(shoppingCartData?.discount)
    };

    return sendAnalytics(CART_EVENT_LIST.COUPON_APPLIED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Coupon Applied' event : ${error}`);
  }
};

/**
 * Sends a tracking event event when entered promotion is denied.
 *
 * @param {Objects} payload : {couponName: string, reason = string, cartID = string, couponId = string}
 * @returns {Promise} Promise object representing the return data from window.analytics.track()
 */
export const couponDenied = (couponName, optionalReason) => {
  try {
    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      coupon_id: couponName,
      coupon_name: couponName,
      order_id: '',
      reason: optionalReason || shoppingCartData?.message
    };

    return sendAnalytics(CART_EVENT_LIST.COUPON_DENIED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Coupon Denied' event : ${error}`);
  }
};

/**
 * Sends a tracking event event when entered promotion is removed.
 *
 * @param {Object} cartId, couponName, discount : {couponName: string, discout = number, cartID = string}
 * @returns {Promise} Promise object representing the return data from window.analytics.track()
 */
export const couponRemoved = couponName => {
  try {
    const trackingPayload = {
      order_id: '',
      cart_id: shoppingCartData?.id,
      coupon_id: couponName,
      coupon_name: couponName,
      discount: parseFloat(shoppingCartData?.discount)
    };

    return sendAnalytics(CART_EVENT_LIST.COUPON_REMOVED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Coupon Removed' event : ${error}`);
  }
};

/**
 * Sends a tracking event with each checkout step viewed with step number.
 *
 * @param {int} stepNumber the number of the checkout steps (0 = customerContactAndPayment, 1 = orderReview and 2 = orderProcessed)
 * @param {string} selectedPaymentOption the payment option selected on a particular checkout step (if applicable)
 *
 */
export const checkoutStepViewed = (stepNumber, selectedPaymentOption = '') => {
  // If shoppingCartData and cartData is empty, don't track the event
  if (!shoppingCartData) {
    return;
  }

  try {
    const trackingPayload = {
      checkout_id: shoppingCartData?.id,
      step: stepNumber + 1,
      payment_method: selectedPaymentOption
    };

    return sendAnalytics(CART_EVENT_LIST.CHECKOUT_STEP_VIEWED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Checkout Step Viewed' event : ${error}`);
  }
};

/**
 * Sends a tracking event with each checkout step completed with step number.
 *
 * @param {int} stepNumber the number of the checkout steps (0 = customerContactAndPayment, 1 = orderReview and 2 = orderProcessed)
 * @param {string} selectedPaymentOption the payment option selected on a particular checkout step (if applicable)
 *
 */
export const checkoutStepCompleted = (stepNumber, selectedPaymentOption = '') => {
  if (!shoppingCartData) {
    return;
  }

  try {
    const trackingPayload = {
      checkout_id: shoppingCartData?.id,
      step: stepNumber + 1,
      payment_method: selectedPaymentOption
    };

    return sendAnalytics(CART_EVENT_LIST.CHECKOUT_STEP_COMPLETED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Checkout Step Completed' event : ${error}`);
  }
};

/**
 * Fire the order completed tracking event once the user has successfully checked out.
 */
export const orderCompleted = orderId => {
  if (!shoppingCartData) {
    return;
  }

  try {
    const trackingPayload = {
      cart_id: shoppingCartData?.id,
      order_id: orderId,
      products: createProductTrackingObject(),
      revenue: parseFloat(shoppingCartData?.subTotal),
      discount: parseFloat(shoppingCartData?.discount),
      currency: shoppingCartData?.currency,
      email: shoppingCartData?.webkey,
      subtotal: parseFloat(shoppingCartData?.subTotal),
      tax: parseFloat(shoppingCartData?.tax),
      total: parseFloat(shoppingCartData?.total),
      ...(shoppingCartData?.promotionCodes[0] && { coupon: shoppingCartData?.promotionCodes[0] })
    };

    return sendAnalytics(CART_EVENT_LIST.ORDER_COMPLETED, trackingPayload);
  } catch (error) {
    console.error(`Something went wrong tracking 'Order Completed' event : ${error}`);
  }
};

/**
 * Make the analytics tracking call, which is what actually sends the data downstream via POST request.
 *
 * @param {*} event
 * @param {*} payload
 * @returns
 */
const sendAnalytics = (event, payload) => {
  if (window.analytics && window.analytics.track) {
    return window.analytics.track(event, payload);
  }

  console.warn('eCommerce analytics are not enabled!');
  return null;
};

/**
 * Utility function to help create the products array object that the downstream tracking is expecting.
 *
 * @returns an array of product objects with desired tracking properties.
 */
const createProductTrackingObject = () => {
  return shoppingCartData?.items?.map((cartItem, index) => ({
    name: cartItem.product?.name,
    position: parseInt(index ?? 0, 10),
    price: parseFloat(cartItem.product.pricingOptions?.find(po => po.autoRenew === true)?.price),
    product_id: cartItem.product?.productSku,
    quantity: parseInt(cartItem.quantity, 10),
    sku: cartItem.product?.productSku
  }));
};

/**
 * Get the current path URL.
 *
 * @returns url
 */
const getCurrentUrl = () => {
  // Get the current URL
  const currentUrl = window.location.href;

  // Parse the URL using URL API
  const url = new URL(currentUrl);

  return `${url.origin}${url.pathname}`;
};

export default {
  registerAnalytics,
  registerCartUpdate,
  cartViewed,
  checkoutStarted,
  couponEntered,
  couponApplied,
  couponDenied,
  couponRemoved,
  productAdded,
  productRemoved,
  productDecreased,
  checkoutStepViewed,
  checkoutStepCompleted,
  orderCompleted,
  productViewed,
  productClicked,
  productListViewed
};
