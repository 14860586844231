/**
 * Represents the development environment which will pull data from the DISW Middleware proxy to DEX.
 */
export const DEV_ENV = 'dev';
/**
 * Represents the UAT environment which will pull data from the DISW middleware UAT proxy (DEX Production).
 */
export const UAT_ENV = 'uat';
/**
 * Represents the production environment which will pull data from the DISW Middleware production proxy to DEX.
 */
export const PROD_ENV = 'prod';
