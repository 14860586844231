import { subscribe, emit, events } from './eventManager';

/**
 * This class is used to emit events for consumers using the component as a web component.
 * If the component is used internally within the universal components,
 * it can be consumed as a Preact component and does not require event emission.
 * Web components do not allow callbacks as props, therefore we need both options (callback and event emissions).
 *
 * @param {object} - An object consisting { id: number, value: number}
 *
 */

function dropDownChanged(id, value) {
  let event = {
    change: true,
    changeValue: value
  };

  emit(events.DROP_DOWN, event, id);
}

/**
 * This is a function to subscribe to the change of drop down
 *
 * @param {function} callback - A callback function as a parameter.
 * @return {object} - An object consisting { id: number, value: number}
 *
 * @example
 *
 *     subscribeToDropDown(callback)
 */
function subscribeToDropDown(callback) {
  return subscribe(events.DROP_DOWN, callback);
}

export default {
  dropDownChanged,
  subscribeToDropDown
};
