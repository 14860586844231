import logger from './logger'
/**
* Get the destinations that are configured for a Segment source.
* @param {string} writekey the Segment source write key
*/

const fetchDestinations = async (writekey) => {
  try {
    const res = await window.fetch(`https://cdn.segment.com/v1/projects/${writekey}/integrations`)
    return await res.json()
  } catch (error) {
    logger.error('Could not fetch destinations', error)
    return []
  }
}

/**
* Returns an array that contains the active OneTrust groups (categories) for the current user. This inspects the
* window.OneTrustActiveGroups global object and returns an array with the integer equivalent of each value.
* For example, if window.OneTrustActiveGroups is ",C0001,C0002,C0003,C0004,", this function will return
* [1, 2, 3, 4]
*/

export const getOneTrustActiveGroups = () => {
  const oneTrustActiveGroupsDefault = window.OnetrustActiveGroups ? window.OnetrustActiveGroups.split(',') : []
  // Example value for oneTrustActiveGroupsDefault = ["", "C0001", "C0002", "C0003", "C0004", ""]; see https://community.cookiepro.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226
  let oneTrustActiveGroups = [];
  // Populate oneTrustActiveGroups array with the integer value equivalents from OneTrust
  oneTrustActiveGroupsDefault.forEach(group => { 
    const groupString = group.replace(/C000/, '')
    const groupInt = parseInt(groupString)
    if (!isNaN(groupInt)) {
      oneTrustActiveGroups.push(groupInt)    
    }
  })
  return oneTrustActiveGroups
}

/**
* Get the Segment destination map to be passed to the analytics.load function as a parameter
* to indicate to Segment which destinations can be enabled given the user's cookie consent choices.
* If the OneTrust consent management solution is not in use on the page then it will return 
* { All: true } to enable all Segment destinations, otherwise it will return destinations 
* in accord with the user's consent choices.
* 
* Usage:
* const destinationMap = await getOneTrustSegmentDestinationMap(writekey)
* analytics.load(writekey, { integrations: destinationMap })
* 
* @param {string} writekey the Segment write key for the source site
*/

export const getOneTrustSegmentDestinationMap = async (writekey) => {
  let oneTrustActiveGroups = getOneTrustActiveGroups()
  const oneTrustCategories = {
    STRICTLY_NECESSARY: 1,
    PERFORMANCE: 2,
    FUNCTIONAL: 3,
    MARKETING: 4
  }
  const segmentCategoryToOneTrustCategoryMap = {
    "A/B Testing": oneTrustCategories.STRICTLY_NECESSARY,
    "Deep Linking": oneTrustCategories.STRICTLY_NECESSARY,
    "Customer Success": oneTrustCategories.STRICTLY_NECESSARY,
    "Feature Flagging": oneTrustCategories.STRICTLY_NECESSARY,
    "Raw Data": oneTrustCategories.STRICTLY_NECESSARY,
    "Security & Fraud": oneTrustCategories.STRICTLY_NECESSARY,
    "Tag Managers": oneTrustCategories.STRICTLY_NECESSARY,
    "Video": oneTrustCategories.STRICTLY_NECESSARY,
    "Analytics": oneTrustCategories.PERFORMANCE,
    "Heatmaps & Recordings": oneTrustCategories.PERFORMANCE,
    "Performance Monitoring": oneTrustCategories.PERFORMANCE,
    "Livechat": oneTrustCategories.FUNCTIONAL,
    "Personalization": oneTrustCategories.FUNCTIONAL,
    "SMS & Push Notifications": oneTrustCategories.FUNCTIONAL,
    "Surveys": oneTrustCategories.FUNCTIONAL,
    "Advertising": oneTrustCategories.MARKETING,
    "Attribution": oneTrustCategories.MARKETING,
    "CRM": oneTrustCategories.MARKETING,
    "Email": oneTrustCategories.MARKETING,
    "Email Marketing": oneTrustCategories.MARKETING,
    "Enrichment": oneTrustCategories.MARKETING,
    "Marketing Automation": oneTrustCategories.MARKETING,
    "Referrals": oneTrustCategories.MARKETING
  }
  let isOneTrustActive = window.OnetrustActiveGroups !== undefined
  let destinationMap = { All: true }
  if (!isOneTrustActive) {
    return destinationMap
  }
  const destinations = await fetchDestinations(writekey)
  destinationMap = { All: false }
  destinations.forEach(destination => {
    destinationMap[destination.creationName] = oneTrustActiveGroups.includes(segmentCategoryToOneTrustCategoryMap[destination.category])
  });
  if (oneTrustActiveGroups.includes(oneTrustCategories.PERFORMANCE)) {
    destinationMap['Segment.io'] = true;
  }
  return destinationMap
}