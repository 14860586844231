/**
 * This is a universal utility for site tracking plans. It leverages Segment and integrates with OneTrust
 * consent manager for GDPR compliance. Provides a single command to load the Segment script and pass it
 * the integrations that can be enabled given the user's consent choices.
 * Usage:
 * <script>
 *  window.disw.init({
 *      track: {
 *          write: 'your write key',
 *          obfuscate: 'your obfuscation key'
 *      }
 *  });
 * </script>
 * This will take care of loading the Segment analytics script with supported destinations for the currrent user.
 * By default, Segment will only be loaded if the user has given consent to Performance cookies, since Segment
 * writes the ajs_anonymous_id cookie whenever it is loaded. This depends on the cookieMonster global object
 * being loaded, which is included with the OneTrust script. If you want to override this (e.g. if your site
 * is not using OneTrust), you can disable it with the optional configuration property onetrust: false,
 * as follows:
 * <script>
 *  window.disw.init({
 *      track: {
 *          write: 'your write key',
 *          obfuscate: 'your obfuscation key',
 *          onetrust: false
 *      }
 *  });
 * In this case Segment will be loaded with all integrations enabled, unless it finds OneTrustActiveGroups
 * in the DOM, in which case it will use them. In either case, Segment will be loaded without waiting for
 * consent for Performance cookies (because the assumption is your site is not using OneTrust for consent
 * management). Do not use this flag to load Segment against user consent - this would violate law.
 * </script>
 */
import { getOneTrustSegmentDestinationMap } from './onetrust-segment';
import logger from './logger';
import { bootstrapAnalytics } from './analytics';

export async function initializeTrack(config = {}) {
  const { write: writekey, obfuscate: obfuscationkey, onetrust } = config;
  if (writekey && obfuscationkey) {
    // Add window.analytics to the window object immediately so sites can call track and identify
    // immediately after instantiation
    bootstrapAnalytics(writekey, obfuscationkey);
    if (onetrust || onetrust === undefined) {
      // OneTrust is required by default unless it has been set to false
      loadSegmentOnlyUponConsent(writekey);
    } else {
      // OneTrust is not required so we can load Segment
      await loadSegment(writekey);
    }
  }
}

/**
 * Reload the page when the user changes their OneTrust consent choices. This
 * ensures that the Segment script is refreshed with the latest destinations based on the
 * user's current consent choices.
 */
export function reloadAnalyticsOnOneTrustConsentChange() {
  window.addEventListener('consent.onetrust', async function () {
    window.location.reload();
  });
  return;
}

/**
 * Wait for OneTrust Cookie Monster to tell us Segment is allowed. This happens on page
 * load if OneTust is enabled
 * @param {String} writeKey Segment write key
 */
export function loadSegmentOnlyUponConsent(writeKey) {
  // Once the user gives Performance consent, load Segment.
  try {
    cookieMonster.once('perf', async () => await loadSegment(writeKey));
  } catch (error) {
    // cookie usage is forbidden (cookieMonster is unavailable)
  }
}

/**
 * Load Segment and all allowed integrations
 * @param {String} writeKey Segment write key
 */
export async function loadSegment(writeKey) {
  try {
    const integrations = await getOneTrustSegmentDestinationMap(writeKey);
    // Register event listener
    reloadAnalyticsOnOneTrustConsentChange();
    // Load Segment with allowed integrations
    analytics.load(writeKey, { integrations });
    analytics.page();
  } catch (error) {
    logger.error('Error while getting analytics script', error);
  }
}