import { CDN_URL } from '../../universal-components/src/constants';
import { DEFAULT_LOCALE_CODE } from './constants/locales';
import logger from './logger';

const FETCH_TIMEOUT = 5000;

/**
 * Loads breadcrumb data from the static CDN for a given site, local and environment. This function
 * expects breadcrumbs to be published to the static CDN (e.g. static.sw.cdn.siemens.com).
 * See {@link getBreadcrumbsUrl} for details. If the breadcrumbs aren't found for the specified
 * locale it will try to load them for the {@link DEFAULT_LOCALE_CODE }.
 *
 * @param {string} site the site id for which to load breadcrumbs
 * @param {string} locale the locale for which to load breadcrumbs (e.g. en-US)
 * @param {string} env the environment for which to load breadcrumbs (default is prod; other options include qa or staging)
 * @returns JSON object representing the breadcrumb data as loaded from the static CDN
 */
export async function fetchBreadcrumbs(site, locale = DEFAULT_LOCALE_CODE, env = 'prod') {
  if (!site) {
    return {};
  }
  const controller = new AbortController();
  setTimeout(() => controller.abort(), FETCH_TIMEOUT);
  return fetch(getBreadcrumbsUrl(site, locale, env), {
    signal: controller.signal,
  })
    .then(response => response.json())
    .catch(() => {
      logger.log(
        `Could not load breadcrumbs for locale ${locale}; refetching for ${DEFAULT_LOCALE_CODE}`
      );
      return fetch(getBreadcrumbsUrl(site, DEFAULT_LOCALE_CODE, env), {
        signal: controller.signal,
      })
        .then(response => response.json())
        .catch(error => error);
    });
}

/**
 * Get the URL for the breadcrumbs JSON file for the specified site, locale and environment from the static CDN.
 *
 * Data is expected to exist at ${CDN_URL}/breadcrumbs/${site}/${env}/breadcrumbs-${locale}.json.
 *
 * @param {string} site the identifier for the site
 * @param {string} locale the locale (defaults to en-US)
 * @param {string} env the environment (defaults to prod; other options include qa and staging)
 * @returns URL to the breadcrumbs file
 */
function getBreadcrumbsUrl(site, locale = DEFAULT_LOCALE_CODE, env = 'prod', host = 'static') {
  return `${CDN_URL}/breadcrumbs/${site}/${env}/breadcrumbs-${locale}.json`;
}
