/**
 * Data provider for localization tokens.
 *
 * Assumes the presence of JSON files published to static CDN in the format as specified in
 * tests/localizationtokens-countryselector-en-US.json. See localizationTokenUtils.fetchLocalizationTokens
 * for details
 */
import { fetchLocalizationTokens } from './localizationTokenUtils';

const DEFAULT_ENV = 'prod';
let localizationEnv = DEFAULT_ENV;
let useSessionStorage = true;

/**
 * Initialize the localization token module to set the environment from which localization tokens will be queried.
 *
 * @param {*} config should contain an 'env' property to set the environment. Defaults to prod.
 */
export async function initializeLocalizations(config = {}) {
  const { env = DEFAULT_ENV, sessionStorage = true } = config;
  // all localization tokens for 'uat' env should be taken from 'prod' env
  localizationEnv = env === 'uat' ? 'prod' : env;
  useSessionStorage = sessionStorage;
}

/**
 * Loads localization toeksn from the static CDN for a given slug, environment and locale.
 * @example Example data format of localization token file
 * {
 *   "locale": "en-US",
 *   "title": "Select your country",
 *   "youre_viewing_products": "You're viewing products for {country_name}. {product_name} availability and pricing varies by location.",
 *   "select_your_country": "Select your country so we can provide you with the correct information."
 * }
 *
 * @param {string} slug the slug for which to load localization tokens (required)
 * @param {string} locale the locale for which to load breadcrumbs (defaults to en-US)
 * @param {boolean} [doubleBraces=false] Whether or not to double brace any templated tokens (required for preact-i18n library)
 * @async
 */
async function loadLocalizationTokens(slug, locale, doubleBraces = false) {
  let localizationTokens;
  const sessionStorageKey = `tokens_${locale}_${slug}`;

  try {
    // Get from session storage, if enabled
    if (useSessionStorage) {
      const tokenString = window.sessionStorage.getItem(sessionStorageKey);

      // If there is a value, convert from string to object
      if (tokenString) {
        localizationTokens = JSON.parse(tokenString);
      }
    }

    if (!localizationTokens) {
      // If nothing found (session storage not available or not found), load from API
      localizationTokens = await fetchLocalizationTokens(slug, locale, localizationEnv);

      if (localizationTokens) {
        // Save in session storage as string
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(localizationTokens));
      }
    }

    if (doubleBraces) {
      return doubleTokenBraces(localizationTokens);
    }

    return localizationTokens;
  } catch (error) {
    return 'Could not load localization tokens', error;
  }
}

/**
 * Take any token value and double up an templated parts of the string
 *
 * @param {object} localizationTokens Must be an object in the form { keyName: 'This is a string', otherKey: 'The is {another} string'}
 */
const doubleTokenBraces = localizationTokens => {
  if (typeof localizationTokens !== 'object') {
    return localizationTokens;
  }

  const doubledTokens = {};
  for (const key in localizationTokens) {
    // "This {is} a {test}" becomes "This {{is}} a {{test}}"
    doubledTokens[key] = localizationTokens[key].replace(new RegExp(/\{(.*?)\}/, 'g'), '{{$1}}');
  }

  return doubledTokens;
};

export default {
  loadLocalizationTokens,
  doubleTokenBraces
};
