import { CDN_TRANSLATIONS_URL } from './constants/api_urls';
import { DEFAULT_LOCALE_CODE } from './constants/locales';

/**
 * Loads localization tokens from the static CDN for a given slug, environment, and locale. This function
 * expects the Localization Token Container to be setup in Contentful and published to the static CDN
 * (e.g. static.sw.cdn.siemens.com).
 *
 * @param {string} slug
 * @param {string} locale
 * @param {string} env
 * @returns JSON object representing the localization tokens as loaded from the static CDN
 */
export async function fetchLocalizationTokens(slug, locale = DEFAULT_LOCALE_CODE, env = 'prod') {
  // Can't get data for a non-existent slug
  if (!slug) {
    return {};
  }

  const controller = new AbortController();
  setTimeout(() => controller.abort(), 3000);

  return fetch(getLocalizationTokenUrl(slug, locale, env), {
    signal: controller.signal
  })
    .then(response => (response.ok ? response.json() : undefined))
    .catch(error => console.log('getLocalizationTokenUrl fetch error: ', error));
}

/**
 * Get the URL for the tokens JSON file for the specified site, locale and environment from the static CDN.
 *
 * @param {string} site the identifier for the site
 * @param {string} locale the locale (defaults to en-US)
 * @param {string} env the environment (defaults to prod; other options include qa and staging)
 * @returns URL to the breadcrumbs file
 */
function getLocalizationTokenUrl(slug, locale = DEFAULT_LOCALE_CODE, env = 'prod') {
  return `${CDN_TRANSLATIONS_URL}/${env}/${slug}/translation-${locale}`;
}
