export const events = {
    LOCALES: 'locales',
    ACCOUNT: 'ACCOUNT',
    USER: 'USER',
    BREADCRUMBS: 'BREADCRUMBS',
    ECOMMERCE: 'ECOMMERCE',
    CART: 'CART',
    COUNTRY: 'COUNTRY',
    DROP_DOWN: 'DROP_DOWN',
    NUMERIC_STEPPER: 'NUMERIC_STEPPER',
    ADDED_TO_CART_MODAL: 'ADDED_TO_CART_MODAL',
    MINI_CART: 'MINI_CART',
    FULL_CART: 'FULL_CART',
    LIVE_CHAT: 'LIVE_CHAT',
    SUBCRIPTION_TERMS_CONFLICT_MODAL: 'SUBSCRIPTION_TERMS_CONFLICT_MODAL'
};
const eventMap = new Map();
const eventArgsMap = new Map();
function generateRandomId(event) {
    var _a;
    const randomId = Math.floor(Math.random() * 1000 + 1);
    // Make sure there isn't already an event with the random id.
    if (eventMap.has(event) && ((_a = eventMap.get(event)) === null || _a === void 0 ? void 0 : _a[randomId])) {
        return generateRandomId(event);
    }
    return randomId;
}
export function emit(event, ...args) {
    if (eventMap.has(event)) {
        for (const callback of Object.values(eventMap.get(event))) {
            const theCallback = callback;
            setTimeout(() => {
                theCallback(...args);
            }, 0);
        }
    }
    eventArgsMap.set(event, args); // Pass the array of args as second parameter to Map.set()
}
export function subscribe(event, callback) {
    const id = generateRandomId(event);
    eventMap.has(event) || eventMap.set(event, {});
    eventMap.get(event)[id] = callback;
    // If this event has already been emitted, call the callback with the previous emitted values.
    if (eventArgsMap.has(event)) {
        callback(...eventArgsMap.get(event)); // Since the Map holds array of parameters, need to spread that so they are passed as proper parameters
    }
    // return an unsubscribe function
    return function () {
        delete eventMap.get(event)[id];
    };
}
