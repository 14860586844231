import logger from './logger';
import { DEFAULT_LOCALE_CODE, LOCALES } from './constants/locales';

const LOCALE_REGEX = /[a-z]{2}-[A-Z]{2}/g;
const CONTACT_SALES_URL = 'https://resources.sw.siemens.com/en-US/contact-plm-store';

let supportedLocales = LOCALES.map(l => l.code);

/**
 * Used to retrieve the contact sales URL with the current users locale
 *
 * @returns string url with the new correct local in its place, or undefined if no locale is present
 */
export function getContactSalesUrlWithCurrentLocale() {
  const locale = getCurrentLocaleCode();
  const url = CONTACT_SALES_URL;

  if (!locale) {
    // if we do not a locale in the input then we return undefined
    return '';
  }

  // the url constant we are using has a hardcoded locale of en-US, we swap it out here with the current locale
  return url.replace('en-US', locale);
}

export function initializeLocales(locales) {
  if (locales && Array.isArray(locales)) {
    supportedLocales = locales.reduce((accum, localeCode) => {
      if (!localeCode.match(LOCALE_REGEX)) {
        logger.warn(`locale code ${localeCode} not supported! Please provide 5 character locale codes.`);
      } else {
        accum.push(localeCode);
      }
      return accum;
    }, []);
  }
}

export function getAllLocales() {
  return LOCALES;
}

/**
 * Navigate the window to a new locale, if the locale is supported.
 * @param {*} localeCode The 5 char locale code to navigate to.
 */
function switchLocale(localeCode) {
  if (getCurrentLocaleCode()) {
    const newPath = generateLocalePath(localeCode);
    window.location.pathname = newPath;
  } else {
    logger.warn('There is no locale in the URL!');
  }
}

/**
 * Generate a href to the locale by replacing the locale that is currently in the URL.
 * @param {string} localeCode The 5 char locale to to generate a path for.
 */
function generateLocalePath(localeCode) {
  let path = window.location.pathname;

  if (getCurrentLocaleCode()) {
    return path.replace(LOCALE_REGEX, localeCode);
  } else {
    logger.warn('There is no locale in the URL!');
  }
}

/**
 * Getter function for the current locale code. Look up the locale in the url to determine which locale
 * is currently set. If the URL does not contain the locale then get the detected browser language
 */
function getCurrentLocaleCode() {
  const pathMatch = window.location.pathname.match(LOCALE_REGEX);
  const browserLanguage = window.navigator.language;
  const code = pathMatch ? pathMatch[0] || browserLanguage : browserLanguage;
  const locale = LOCALES.find(l => l.code === code || l.code.startsWith(code.toLocaleLowerCase()));
  /**
   * 1. determine if the detected locale code is in the list of available locales
   * 2. if we don't find a matching locale we know it's not supported
   * 3. if we find a matching locate and have a configured list of supported locales, check for the existence of the detected locale code in the list of supported locles
   * 4. if not supported, then return the default locale which is en-US
   */
  const isSupported = locale?.code && supportedLocales.length > 0 && supportedLocales.includes(locale?.code);
  return isSupported ? locale?.code : DEFAULT_LOCALE_CODE;
}

/**
 * Getter function for the default locale object.
 */
function getDefaultLocale() {
  return DEFAULT_LOCALE_CODE;
}

/**
 * Getter function for the locales that are supported in the current application.
 */
function getSupportedLocales() {
  return supportedLocales;
}

// Put the exports that we want to expose to the module on default
export default {
  getContactSalesUrlWithCurrentLocale,
  switchLocale,
  generateLocalePath,
  getCurrentLocaleCode,
  getDefaultLocale,
  getSupportedLocales,
  getAllLocales
};
