import { getChatConfiguration, loadHoursOfOperation, markProactiveChatResolved, isProactiveCookieWritten } from './chatUtils';
import { areCookiesEnabled } from './utils/cookies';
import { ISO_3166_RUSSIA } from './constants/locales';
import { subscribe, emit, events } from './eventManager';
import {
  HIGH_VALUE_SALES_URL_PATHS,
  LIVE_CHAT_NOT_LOADED_CONTACT_PAGE,
  PRELIM_MODAL_MARKETING,
  PRELIM_MODAL_SALES,
  QUERY_PARAMETERS_SHOPPING_CART
} from '../../universal-components/src/LiveChat/chatConstants';
import logger from './logger';
import { DEV_ENV, PROD_ENV, UAT_ENV } from './constants/environments';

// LiveChat Disabled for the following countries
const LIVECHAT_DISABLED_COUNTRY_LIST = [ISO_3166_RUSSIA];

/**
 * Valid environments for the <disw-live-chat /> component.
 */
const VALID_ENVIRONMENTS = [PROD_ENV, UAT_ENV, DEV_ENV];

/**
 * Default chat environment (production). Updated via the initialization call.
 */
let chatEnv = PROD_ENV;

/**
 * Tracks whether or not an 'Add to Cart' button is available on the current page with pricing options, so that
 * we can show them the appropriate preliminary modal (this is a high value user journey so show sales chat).
 *
 */
let addToCartAvailable = false;

/**
 * Initialize the live chat module via the disw.init({ .. }) call. Currently, only the environment is set
 * via the initialization which determines which API environment to query against, and acts as an indicator
 * for the live chat team to not send chat interactions to live agents.
 *
 * Note: the environment previously was set via a property passed to the Live Chat universal component,
 * which is not how we want to handle environments.
 *
 * @param {object} config containing the initialization environment (dev / prod).
 */
export async function initializeChat(config = {}) {
  const { env = PROD_ENV } = config;
  chatEnv = env;

  // Check if the environment passed to the initialization is a valid environment.
  if (!VALID_ENVIRONMENTS.some(e => e === chatEnv)) {
    console.warn(`Live Chat environment ${env} is not valid`);
  }

  // Subscribe to the country module so that if a country changes, we reset the add to cart availability tracking property.
  window.disw.country.subscribeToCountry(() => {
    addToCartAvailable = false;
  });

  // Subscribe to the eCommerce events so that if a product is fetched successfully, we can mark the add to cart button as available.
  window.disw.cart.subscribeToECommerce(eventName => {
    if (eventName === window.disw.cart.ECOMMERCE_PRODUCT_FETCHED) {
      addToCartAvailable = true;
    }
  });
}

/**
 * Helper function to determine if live chat is in development mode or not.
 *
 * @returns {boolean} indicating if live chat is in development mode or not.
 */
export const isDevModeEnabled = () => {
  logger.log(`Live Chat development mode enabled=${[UAT_ENV, DEV_ENV].some(e => e === chatEnv)}`);
  return [UAT_ENV, DEV_ENV].some(e => e === chatEnv);
};

/**
 * Check if LiveChat is loaded before emitting the specified event
 *
 * @param {String} identifier Event Identifier for a CHAT event
 */
const _checkLiveChatExists = identifier => {
  // There is the possibility that chat is not enabled (cookies disabled, not available in a given country, etc).  In this case, we
  // redirect to the contact page.
  if (!window.icPatronChat) {
    //Adding context parameters to the url to keep track of the page
    window.location.href = window.disw.context.addContextToUrl(LIVE_CHAT_NOT_LOADED_CONTACT_PAGE);
  } else {
    emit(events.LIVE_CHAT, identifier);
  }
};

/**
 * Open the marketing preliminary live chat modal. The function will emit
 * an event which is caught in the Live Chat which triggers the preliminary modal to open.
 */
function initiateMarketingPreliminaryModal() {
  _checkLiveChatExists(PRELIM_MODAL_MARKETING);
}

/**
 * Open the sales preliminary live chat modal. The function will emit
 * an event which is caught in the Live Chat which triggers the preliminary modal to open.
 */
function initiateSalesPreliminaryModal() {
  _checkLiveChatExists(PRELIM_MODAL_SALES);
}

/**
 * Subscribe to the live chat events which are triggered for chat initations.
 *
 * @param {*} callback function to be executed after event emission.
 *
 */
function subscribeToLiveChat(callback) {
  return subscribe(events.LIVE_CHAT, callback);
}

/**
 * Check to see whether or not we should block the pro-active pop-up from the page based on the presence of
 * a list of query parameters and URL paths. Typical scenarios where we should block proactive pop include when the user
 * is in the midst of a high-value journey such as purchasing.
 *
 * @returns {boolean} indicating whether or not we should BLOCK the proactive pop-up for live chat.
 */
export const shouldBlockProactivePop = () => {
  const highValueJourney = isPotentiallyHighValueJourney();
  logger.log(`[shouldBlockProactivePop] highValueJourney=${highValueJourney}`);

  // We will block the proactive popup if this is a high value journey.
  return highValueJourney;
};

/**
 * Determine if a chat agent is available by loading the hours of operation via API, which checks to see
 * if the current UTC time is within the defined hours of operation.
 *
 */
export async function isChatAgentAvailable() {
  const chatHoursOfOperation = await loadHoursOfOperation(chatEnv);

  // If we found hours of operation, check if we're within the "open" window meaning agents are available.
  if (chatHoursOfOperation) {
    logger.log(`[isChatAgentAvailable] chatHoursOfOperation.open=${chatHoursOfOperation.open}`);
    return chatHoursOfOperation.open;
  }

  logger.log('[isChatAgentAvailable] chat agent unavailable due to no hours of operation being found.');
  return false;
}

/**
 * Determine if the live icon should be shown on the ThreeboxComponent pages ex: https://plm.sw.siemens.com/en-US/contact-plm/
 *
 */
export async function getLiveIconData() {
  const isOpen = await isChatAgentAvailable();
  const isCookies = areCookiesEnabled();
  return {
    isChatAgentAvailable: isOpen,
    areCookiesEnabled: isCookies
  };
}

/**
 * Get the live chat configuration managed in Contentful for the specific site. The utility function
 * will check the current domain via the window object, and will pull the appropriate configuration from the CMS.
 *
 * @param {*} [env] being queried against.
 *
 * @returns {object} representing the configuration for the current site.
 */
export async function getLiveChatConfiguration() {
  const chatConfiguration = await getChatConfiguration(chatEnv);
  logger.log(`[getLiveChatConfiguration] chatConfiguration=${JSON.stringify(chatConfiguration)}`);
  return chatConfiguration;
}

/**
 * Check if chat is disabled for the users current country via geolocation. The function checks to see if the country
 * returned from geolocation is in the managed list of blocked countries where chat should not appear.
 *
 * @returns {boolean} indicating if chat should be disabled for the users country.
 */
export async function isChatDisabledForCountry() {
  try {
    const disabledCountries = getBlockedCountryList();

    if (disabledCountries.length > 0) {
      const country = window.disw.country.getCountry();

      if (country?.countrycode) {
        if (disabledCountries.includes(country.countrycode)) {
          logger.log(`[isChatDisabledForCountry] chat is disabled for country=${country.countrycode}. `);
          return true; // We're done
        }
      }
    }
  } catch (error) {
    // Couldn't retrieve country information, carry on
  }

  logger.log('[isChatDisabledForCountry] chat is not disabled for the user country. ');
  return false;
}

/**
 * Get the list of countries for which chat should be disabled
 *
 * @returns Array of ISO 639-1 country codes for blocked countries
 */
export const getBlockedCountryList = () => {
  return LIVECHAT_DISABLED_COUNTRY_LIST;
};

/**
 * Mark proactive chat as being resolved, so that it does not re-pop within the allotted time. The function
 * accepts the number of days that chat should not auto-pop for so that it can vary based on scenario.
 *
 * @param {*} days number of days the auto-pop should be suppressed for.
 * @param {*} devmode indicates if we're in a development environment where we're allowed to write cookies freely without consent checks.
 */
const markProactiveResolved = days => {
  logger.log(`[markProactiveResolved] days=${days} isDevMode=${isDevModeEnabled()}`);
  markProactiveChatResolved(days, isDevModeEnabled());
};

/**
 * Check if the proactive cookie is set in the users browser so that we can determine if they should receive the auto-pop again
 * for the preliminary chat model.
 *
 * @returns {boolean} indicating if the proactive cookie is set or not on the users local machine.
 */
const isProactiveCookieSet = () => {
  const isCookieSet = isProactiveCookieWritten();
  logger.log(`isProactiveCookieSet=${isCookieSet}`);
  return isCookieSet;
};

/**
 * Check if purchasing is available on the current page via the presence of an 'add to cart' button. If pricing information is fetched successfully,
 * an event is emitted (subscribed in {@link initializeChat}) which tells us a product is available for purchasing.
 *
 * @returns {boolean} indicating if a product is available for adding to the shopping cart on the current page.
 */
export const isPurchasingAvailableOnPage = () => {
  logger.log('[isPurchasingAvailableOnPage] purchasing is available on the current page.');
  return addToCartAvailable;
};

/**
 * Determine if the user is potentially in a "high value journey" (e.g. about to buy something) by looking at the URL path and query parameters.
 *
 * @returns {boolean} true if we are in a high value journey, false if not
 */
export const isPotentiallyHighValueJourney = () => {
  // Get the URL query params
  const queryParams = new URLSearchParams(window.location.search);
  // If any shopping cart parameters are in the URL, this is a high value journey
  if (QUERY_PARAMETERS_SHOPPING_CART.some(param => queryParams.has(param))) {
    logger.log('[isPotentiallyHighValueJourney] high value journey due to presence of a identified query param.');
    return true;
  }

  // Get the URL paths for the current page
  const urlPaths = window.location.pathname.split('/');
  // If any of the paths in the URL are high value, this is a high value journey
  if (HIGH_VALUE_SALES_URL_PATHS.some(urlPath => urlPaths.includes(urlPath))) {
    logger.log('[isPotentiallyHighValueJourney] high value journey due to presence of a identified URL path.');
    return true;
  }

  // Return false by default
  return false;
};

export default {
  getLiveChatConfiguration,
  initializeChat,
  initiateMarketingPreliminaryModal,
  initiateSalesPreliminaryModal,
  isChatAgentAvailable,
  getLiveIconData,
  isChatDisabledForCountry,
  isDevModeEnabled,
  isPotentiallyHighValueJourney,
  isPurchasingAvailableOnPage,
  isProactiveCookieSet,
  markProactiveResolved,
  shouldBlockProactivePop,
  subscribeToLiveChat
};
