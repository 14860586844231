import { subscribe, emit, events } from './eventManager';

/**
 * This is a function used by the backend to set the NumericStepper value and emit the set of event named NUMERIC_STEPPER
 * to be used to directly change the numeric stepper. This does not happen by UI changes, only in cases like if there
 * is an error and we need to revert back.
 *
 * @param {object} stepper - An object consisting { id: number, value: number}
 *
 */
function numericStepperIncreased(id) {
  let event = {
    increase: true,
    change: false
  };

  emit(events.NUMERIC_STEPPER, event, id);
}

/**
 * This is a function to set the NumericStepper value and emit the change of event named NUMERIC_STEPPER
 * to be used when the numeric stepper is emitting a change. This is used in the case of UI interaction
 * chaning the stepper value via +/- or input field.
 *
 * @param {object} stepper - An object consisting { id: number, value: number}
 *
 */
function numericStepperDecreased(id) {
  let event = {
    increase: false,
    change: false
  };

  emit(events.NUMERIC_STEPPER, event, id);
}

function numericStepperChanged(id, value) {
  let event = {
    increase: false,
    change: true,
    changeValue: value
  };

  emit(events.NUMERIC_STEPPER, event, id);
}

/**
 * This is a function to subscribe to the change of numeric stepper
 *
 * @param {function} callback - A callback function as a parameter.
 * @return {object} - An object consisting { id: number, value: number}
 *
 * @example
 *
 *     subscribeToNumericStepper(callback)
 */
function subscribeToNumericStepper(callback) {
  return subscribe(events.NUMERIC_STEPPER, callback);
}

export default {
  numericStepperIncreased,
  numericStepperDecreased,
  numericStepperChanged,
  subscribeToNumericStepper
};
