import logger from './logger';
import { emit, events } from './eventManager';
import addSecretKeyAndHash from './shaHashing';
import { addQueryParameter } from './queryUtils';
import { getDEXServicesAPIURL } from './constants/api_urls';
import { ECOMMERCE_UNSUPPORTED_COUNTRIES } from './constants/locales';
const FETCH_TIMEOUT = 60000;
const CART_SOURCE = 'DISW';
const FULL_CART_QUERY_PARAM = 'fullCart';
/**
 * Creates DEX cart through the DEX middleware. Cart creation requires the country
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} countryCode ISO-3166 country code
 *
 * @returns {Promise} Promise object representing the cart data as loaded from the DEX middleware
 */
export const createCart = async (env, countryCode) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    const body = JSON.stringify({
        sapPayerId: sessionStorage.getItem('spi'),
        cartSource: CART_SOURCE,
        country: countryCode,
        returnUrl: addQueryParameter(window.location.href, FULL_CART_QUERY_PARAM, 'true')
    });
    //Get the hashed value of the stringfied body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    return fetch(`${getDEXServicesAPIURL(env)}/cart`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        logger.error(`Error creating cart. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Loads DEX cart data from the DEX middleware.  It is expected that the cart has already been inititalized,
 * otherwise there isn't a stored cartId to get the cart contents.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} cartId the id of the DEX cart
 *
 * @returns {Promise} Promise object representing the cart data as loaded from the DEX middleware
 */
export const loadCartData = async (env, cartId) => {
    const controller = new AbortController();
    // Get the users webkey email address and set the query param if necessary
    const webkey = webkeyEmail();
    // If we have a webkey email, append it as a query parameter as DEX requires it to be passed.
    const queryParameters = webkey ? `?email=${webkey}` : '';
    const urlPath = `/cart/${cartId}${queryParameters}`;
    //Get the hashed value of the urlpath with the secret key
    const sha256HashedUrl = addSecretKeyAndHash(urlPath);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    //Pass the hashed value in the authorization
    return fetch(`${getDEXServicesAPIURL(env)}${urlPath}`, {
        signal: controller.signal,
        headers: {
            Authorization: `Bearer ${sha256HashedUrl}`
        }
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        logger.error(`Error loading cart with id '${cartId}'. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        return response.text().then(text => {
            // we throw the text because the text prop is what contains the value used to determine a cart mismatch
            throw text;
        });
    })
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Adds an item to the DEX cart.  It is expected that the cart has already been initialized,
 * otherwise there isn't a stored cartId to get the cart contents.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} cartId the id of the DEX cart
 * @param {string} pricingId the pricingId for the item to add to the cart
 * @param {string} sku The SKU of the product being added. This is used server-side to validate that an existing product with a different subscription term is not being added.
 * @param {string | number} quantity the number of the given productPricingId to add
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
export const addItemToCart = async (env, cartId, pricingId, sku, quantity) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    const body = JSON.stringify({
        cartId,
        items: [
            {
                pricingId,
                sku,
                quantity
            }
        ],
        email: webkeyEmail()
    });
    //Get the hashed value of the stringified body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    return fetch(`${getDEXServicesAPIURL(env)}/cart/add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        if (response.status === 400) {
            return response.json();
        }
        logger.error(`Error adding item with id '${pricingId}' to cart data. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        logger.error(`Error adding item with id '${pricingId}' to cart data. This could be caused by the cartId '${cartId}'. API returned - '${error}'`);
        // throw error to display state to user down the line
        throw error;
    });
};
/**
 * Removes an item from the DEX cart.  It is expected that the cart has already been initialized,
 * otherwise there isn't a stored cartId to get the cart contents.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} cartId the id of the DEX cart
 * @param {string} cartItemId the cartItemId for the item to remove from the cart
 *
 * @returns {} object representing the cart data as returned from the DEX middleware
 */
export const removeItemFromCart = async (env, cartId, cartItemId) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    const body = JSON.stringify({
        cartId,
        cartItemIds: [cartItemId],
        email: webkeyEmail()
    });
    //Get the hashed value of the stringified body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    return fetch(`${getDEXServicesAPIURL(env)}/cart/remove`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        logger.error(`Error removing item with id '${cartItemId}' from cart data. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Applies a promotional code to the DEX cart.  It is expected that the cart has already been initialized,
 * otherwise there isn't a stored cartId to get the cart contents.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} cartId the id of the DEX cart
 * @param {string} promotionCode the promotion code to apply to the cart
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
export const applyPromotionCodeToCart = async (env, cartId, promotionCode) => {
    const controller = new AbortController();
    const body = JSON.stringify({
        cartId,
        promotionCode,
        email: webkeyEmail()
    });
    //Get the hashed value of the stringified body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    return fetch(`${getDEXServicesAPIURL(env)}/cart/promotion`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => response.json())
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Removes a promotional code from the DEX cart.  It is expected that the cart has already been initialized,
 * otherwise there isn't a stored cartId to get the cart contents.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} cartId the id of the DEX cart
 * @param {string} promotionCode the promotion code to remove from the cart
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
export const removePromotionCodeFromCart = async (env, cartId, promotionCode) => {
    const controller = new AbortController();
    const body = JSON.stringify({
        cartId,
        promotionCode,
        email: webkeyEmail()
    });
    //Get the hashed value of the stringified body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    return fetch(`${getDEXServicesAPIURL(env)}/cart/promotion/remove`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        logger.error(`Error applying promo code '${promotionCode}'. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Gets Product Information from DEX.
 *
 * @param {string} env the environment (env/prod) used for API calls
 * @param {string} productSku the SKU for the product
 * @param {string} countryCode ISO-3166 country code
 *
 * @returns {Promise} Promise object representing the product data as returned from the DEX middleware
 */
export const getProduct = async (env, productSku, countryCode) => {
    const controller = new AbortController();
    const urlPath = `/product/${productSku}?country=${countryCode}`;
    //Get the hashed value of the urlpath with the secret key
    const sha256HashedUrl = addSecretKeyAndHash(urlPath);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    return fetch(`${getDEXServicesAPIURL(env)}${urlPath}`, {
        headers: {
            Authorization: `Bearer ${sha256HashedUrl}`
        },
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        logger.error(`Error getting product with id '${productSku}' with country code '${countryCode}'. API returned - ${response.status} - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Get a Salesforce user country by their email address.
 *
 * @param {*} env the environment (env/prod) user for API calls
 * @param {*} email the salesforce users email address
 *
 * @returns  Promise object representing the users country details.
 */
export const getUserCountry = async (env, email) => {
    const controller = new AbortController();
    const urlPath = `/user/${email}/country`;
    //Get the hashed value of the urlpath with the secret key
    const sha256HashedUrl = addSecretKeyAndHash(urlPath);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    return fetch(`${getDEXServicesAPIURL(env)}${urlPath}`, {
        headers: {
            Authorization: `Bearer ${sha256HashedUrl}`
        },
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        console.error(`Error with /user/email/country'. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        throw error;
    });
};
/**
 * Update a Salesforce user accounts associated country.
 *
 * @param {string} env the environment (env/prod) user for API calls
 * @param {string} email the salesforce users email address
 * @param {string} country ISO-3166 country code
 *
 * @returns Promise object representing the status of the update user patch request.
 */
export const updateUserCountry = async (env, email, country) => {
    const controller = new AbortController();
    const body = JSON.stringify({
        email,
        country
    });
    //Get the hashed value of the stringified body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    return fetch(`${getDEXServicesAPIURL(env)}/user/update/country`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        console.error(`Error with /user/update/country'. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        throw error;
    });
};
/**
 * Convert a DEX shopping cart between countries, persisting products which are available
 * in both countries.
 *
 * @param {*} env the environment (env/prod) user for API calls
 * @param {*} cartId UUID of the shopping cart
 * @param {*} keepInvalidItems keep invalid items in the shopping cart regardless of status in destination country
 * @param {*} country ISO-3166 country code
 *
 * @returns Promise object representing the status of the cart conversion with the updated shopping cart.
 */
export const convertCart = async (env, cartId, keepInvalidItems, country) => {
    // First check to ensure that the country we are trying to create the cart in is not unsupported by DEX
    if (ECOMMERCE_UNSUPPORTED_COUNTRIES.includes(country)) {
        // Yeet out an event that will remove the cart data, emptying the cart
        emit(events.CART, {}, false);
        // Now set the country to keep state accurate
        window.disw.country.getAllCountries(window.disw.locales.getCurrentLocaleCode()).then((countryList) => {
            const countryOption = countryList.countries.find(option => option.value === country);
            window.disw.country.setCountry({
                countryname: countryOption ? countryOption.label : country,
                countrycode: countryOption ? countryOption.value : country
            });
        });
        // Throw an error, which will be caught in cart.js in the updateCartCountry function and result in the cart cookie being deleted
        const errorMessage = `eCommerce is not supported for country ${country}`;
        logger.warn(errorMessage);
        throw new Error(errorMessage);
    }
    const controller = new AbortController();
    const body = JSON.stringify({
        cartId,
        changeUserCountry: false,
        country,
        keepInvalidItems
    });
    //Get the hashed value of the stringified body with the secret key
    const sha256HashedBody = addSecretKeyAndHash(body);
    setTimeout(() => controller.abort(), FETCH_TIMEOUT);
    return fetch(`${getDEXServicesAPIURL(env)}/cart/convert`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sha256HashedBody}`
        },
        body,
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        console.error(`Error with cart/convert'. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        // Throw the error so it can be caught
        throw error;
    });
};
/**
 * Check if tax has already been applied to the shopping cart. Tax is applied on the DEX side once the customer gets to the \
 * order review page. Therefore if the total and subtotals are not matching, it means tax has been applied.
 *
 * @param {*} cartData represents the active shopping cart containing products, totals and more.
 * @returns {boolean} indicating if the tax has already been added to a shopping cart.
 */
export const taxAlreadyCalculated = (cartData) => {
    // The total and subtotal should always match until tax is applied.
    return cartData && cartData.total !== cartData.subTotal;
};
/**
 * Check if the shopping cart has already been paid for, in which case we should create the customer a new shopping
 * cart so that they are not trying to modify a completed shopping cart.
 *
 * @param {*} cartData represents the active shopping cart containing products, totals and more.
 * @returns {boolean} indicating if the shopping cart has been paid for.
 */
export const isCartPaidFor = (cartData) => {
    return cartData && cartData.isCartPaid;
};
/**
 * Helper function to get the authenticated users email address from the account module.
 *
 * @returns user email addressed. If user is unauthenticated, undefined will be returned.
 */
const webkeyEmail = () => {
    var _a, _b;
    return (_b = (_a = window.disw.account.getAccount()) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email;
};
/**
 * Parse the cart ID from the shopping cart URL which contains it as a query parameter. DEX provides two versions
 * of the ID - a plaintext ID, and a URI - the latter of which needs to be parsed from the cart URL property.
 *
 * @param {*} cartData shopping cart data.
 * @returns URI of the shopping cart.
 */
export const parseCartID = (cartData) => {
    if (cartData && (cartData === null || cartData === void 0 ? void 0 : cartData.url)) {
        const cartUrl = new URL(cartData === null || cartData === void 0 ? void 0 : cartData.url);
        const cartUrlParams = new URLSearchParams(cartUrl.search);
        return cartUrlParams.has('cartID') ? cartUrlParams === null || cartUrlParams === void 0 ? void 0 : cartUrlParams.get('cartID') : '';
    }
    return '';
};
