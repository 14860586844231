import { subscribe, emit, events } from './eventManager';

/**
 * This is a function to to close the modal, either by closing it outright, or hitting the "view cart" button
 * which the consumer of the component can intercept, to hook in custom actions.
 *
 * @param proceedToCart {boolean} indicates whether the user closed the cart outright or  hit "view cart"
 * @param context {boolean} represents the calling context so that two subscribers can determine if they should handle the event.
 *
 */
function closeModal(proceedToCart, context) {
  if (proceedToCart) {
    emit(events.ADDED_TO_CART_MODAL, true, context);
    return;
  }

  emit(events.ADDED_TO_CART_MODAL, false, context);
}

/**
 * Subscribe to the modal events (user closed the modal, or user hit view cart)
 *
 * @param {cartCallback} callback
 * @return {function} function used to subscribe to the modal events
 *
 * @example
 *     subscribeToModal(callback)
 */
function subscribeToModal(callback) {
  return subscribe(events.ADDED_TO_CART_MODAL, callback);
}

export default {
  closeModal,
  subscribeToModal
};
