// DISW Tracking Event Names for DEX Tracking
export const CART_EVENT_LIST = {
  CART_VIEWED: 'Cart Viewed',
  CHECKOUT_STARTED: 'Checkout Started',
  COUPON_ENTERED: 'Coupon Entered',
  COUPON_APPLIED: 'Coupon Applied',
  COUPON_DENIED: 'Coupon Denied',
  COUPON_REMOVED: 'Coupon Removed',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_REMOVED: 'Product Removed',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  ORDER_COMPLETED: 'Order Completed',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_LIST_VIEWED: 'Product List Viewed'
};
