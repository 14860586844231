import { subscribe, emit, events } from './eventManager';
import { fetchCountry, fetchCountries } from './countryUtils';
import { COOKIE_DEFAULT_PATH, COOKIE_MAX_AGE, COOKIE_SAMESITE_STRICT, getCookieDomain, getCookieValue, setCookie } from './utils/cookies';
import { DEV_ENV, PROD_ENV, UAT_ENV } from './constants/environments';
import logger from './logger';
/**
 * Name of the cookie used to store the users geolocated country (which is overridden manually in the country selector).
 */
const COUNTRY_STORAGE_KEY = 'SelectedCountry';
/**
 * Host domain name used to write the cookies so that they are environment specific (stage.sw.siemens.com, sw.siemens.com).
 */
let countryCookieDomain;
/**
 * Default country initially set to ensure a country is selected.
 */
const DEFAULT_COUNTRY = { countryname: 'United States', countrycode: 'US' };
/**
 * Tracks the active country for the module.
 */
let country = DEFAULT_COUNTRY;
/**
 * Tracks the current API environment from which to make queries. Updated via disw.init({..}).
 */
let countryEnv = PROD_ENV;
/**
 * This is a function to fetch the country
 *
 * @param {Country} Country - An object of Country
 *
 * @example
 *
 *     initializeCountry()
 */
export async function initializeCountry(config = {}) {
    const { env = PROD_ENV } = config;
    countryEnv = env;
    // Get the cookie domain, passing whether this is a DEV or UAT environment
    countryCookieDomain = getCookieDomain([DEV_ENV, UAT_ENV].includes(countryEnv));
    // Attempt to get the country from local storage
    const storedCountry = _getLocally();
    // If there is a stored country set the country with stored result
    if (storedCountry) {
        country = storedCountry;
        emit(events.COUNTRY, storedCountry, false);
        return;
    }
    // If nothing in localStorage, fetch the country from middleware
    try {
        const fetchedCountry = await fetchCountry(countryEnv);
        // Change format as expected, setCountry will save to localStorage and emit the event
        setCountry({
            countrycode: fetchedCountry.countryCode,
            countryname: fetchedCountry.countryName
        });
        return;
    }
    catch (error) {
        logger.error(error);
    }
    // Otherwise, use the default
    setCountry(country);
}
/**
 * This is a function to pull the list of countries from Contentful. Cache the country list per locale
 *
 * @returns - list of countries in an array with a label (i.e., Argentina) and a 2-digit country code (i.e., AR)
 *
 * @example
 *      getAllCountries('en-US')
 */
async function getAllCountries(localeCode) {
    const sessionStorageKey = `countries_${localeCode}`;
    try {
        const cachedCountriesList = window.sessionStorage.getItem(sessionStorageKey);
        if (cachedCountriesList) {
            return JSON.parse(cachedCountriesList);
        }
        // Pull the list of countries and cache in session storage
        const countryList = await _fetchCountriesWithRetries(localeCode, countryEnv, 1);
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(countryList));
        return countryList;
    }
    catch (error) {
        logger.error(error);
    }
}
/**
 * Pull the list of countries from Contentful. Reload the country list if it fails in first attempt.
 *
 * @param {string} localeCode
 * @param {string} countryEnv
 * @param {number} retries
 *
 * @returns list of countries in an array with a label.
 */
async function _fetchCountriesWithRetries(localeCode, countryEnv, retries) {
    let countries;
    try {
        countries = await fetchCountries(localeCode, countryEnv);
        return countries;
    }
    catch (error) {
        if (retries > 0) {
            return _fetchCountriesWithRetries(localeCode, countryEnv, retries - 1);
        }
        logger.error(`Error loading country data: ${error} with retries '${retries}'`);
        throw error;
    }
}
/**
 * This is a function to set the country and emit the change of event named COUNTRY
 *
 * @param {Country} newCountry - An object of Country
 *
 * @example
 *     setCountry({countryname: 'United States', countrycode: 'US'})
 */
function setCountry(newCountry) {
    if (typeof (newCountry === null || newCountry === void 0 ? void 0 : newCountry.countryname) !== 'undefined') {
        // Store the country in a cookie so that it persists across sites.
        _storeLocally(newCountry);
        // Indicate the country has changed.
        emit(events.COUNTRY, newCountry, false);
        // Set country as the new result after emit
        country = newCountry;
        return;
    }
    // Indicate the country selector was closed.
    emit(events.COUNTRY, undefined, true);
}
/**
 * Store Country in localstorage
 *
 * @param {Country} countryData An object consisting countryname and countrycode
 * @example
 *     storeLocally({countryname: 'United States', countrycode: 'US'})
 */
function _storeLocally(countryData) {
    // Store value as a string
    setCookie(COUNTRY_STORAGE_KEY, JSON.stringify(countryData), {
        maxAge: COOKIE_MAX_AGE,
        domain: countryCookieDomain,
        path: COOKIE_DEFAULT_PATH,
        // Only sends the cookie with requests from the cookie's origin site. Server doesn't use this cookie so don't send any more than necessary
        sameSite: COOKIE_SAMESITE_STRICT
    });
}
/**
 * Get the Country object from localStorage.
 *
 * @returns {Country|null} Returns null of nothing stored locally
 */
function _getLocally() {
    const countryString = getCookieValue(COUNTRY_STORAGE_KEY);
    if (countryString) {
        // Get a JSON object from the stored string
        return JSON.parse(countryString);
    }
    // Otherwise, will return null
    return countryString;
}
/**
 * @callback countryCallback
 * @param {Country} country data for newly selected country
 * @param {boolean} error whether an error occurred selecting a new country from the country selector.
 *                        A true value would indicate the country selector was closed without a country
 *                        being selected.
 *
 * Function to subscribe to any changes in the country.
 *
 * @param {countryCallback} callback
 * @return {function} function used to unsubscribe from the country events
 *
 * @example
 *     subscribeToCountry(callback)
 */
function subscribeToCountry(callback) {
    return subscribe(events.COUNTRY, callback);
}
/**
 * This is a function to return the current country object
 *
 * @return {Object} country - An object containing current country code and country name
 *
 *  @example
 *
 *     getCountry()
 */
export function getCountry() {
    return country;
}
export default {
    getAllCountries,
    setCountry,
    subscribeToCountry,
    getCountry
};
