// The URL for the script loading inContact.
export const CHAT_SCRIPT_URL = 'https://home-e31.niceincontact.com/inContact/ChatClient/js/embed.min.js';
// The DOM ID assigned to the dynamically loaded inContact script.
export const CHAT_SCRIPT_ID = 'dtm';
// The height from the bottom of the chatbox ((600) + height of the bottom button (70) + the margin of the button (40)
export const CHAT_HEIGHT_FROM_BOTTOM = 710;
// inContact: server host used for initiating chat sessions.
export const CHAT_SERVERHOST = 'https://home-e31.niceincontact.com';
// inContact: business number identifying the application calling inContact.
export const CHAT_BUS_NO = 4598220;
// inContact: Point of contact ID used for identifying the routing on the inContact side.
export const CHAT_POC = '1ec356dd-8899-42ed-ace6-4dbad6ec2f1f';
// Identifier for the live chat labels managed in the CMS.
export const LIVECHAT_TRANSLATIONS_SLUG = 'livechat-proactive';
// The default time after which the chat will proactively pop-up.
export const LIVECHAT_MODAL_AUTO_POP_DEFAULT = 30000;
// The default time after which the chat will proactively pop-up (in the development environment).
export const LIVECHAT_MODAL_AUTO_POP_UP_DEV = 5000;
// The default time which the proactively popped chat will close if no action is taken by the user.
export const LIVECHAT_MODAL_AUTO_CLOSE_DEFAULT = 30000;
// The default time after which the chat will proactively pop-up (in the development environment).
export const LIVECHAT_MODAL_AUTO_POP_CLOSE_DEV = 5000;
// LiveChat pameter delimiter (chosen for uniqueness amongs common strings)
export const LIVECHAT_PARAM_DELIMITER = 'Ω';
// Identifier for the marketing preliminary modal.
export const PRELIM_MODAL_MARKETING = 'marketingChat';
// Identifier for the sales preliminary modal.
export const PRELIM_MODAL_SALES = 'salesChat';
// Chat type sent to incontact for routing general marketing sales leads
export const INCONTACT_CHAT_TYPE_SALES = 'sales';
// Chat type sent to incontact for routing support leads
export const INCONTACT_CHAT_TYPE_SUPPORT = 'support';
// Chat type sent to incontact for routing product info leads
export const INCONTACT_CHAT_TYPE_PRODUCT_INFO = 'info';
// Chat type sent to incontact for routing inside sales leads (capable of transacting sales)
export const INCONTACT_CHAT_TYPE_INSIDE_SALES = 'insideSales';
// Chat type sent to incontact for routing sales leads from within the shopping cart
export const INCONTACT_CHAT_TYPE_CART = 'cart';
// Query parameters which when present, will show the SALES preliminary modal. NOTE this used to include DEXs' cart parameters but I took them out ;)
export const QUERY_PARAMETERS_SHOPPING_CART = ['fullCart'];
// URL paths that will show the preliminary sales modal.
export const HIGH_VALUE_SALES_URL_PATHS = ['ccrz__Cart', 'ccrz__CheckoutNew', 'ccrz__OrderConfirmation'];
// Cookie name which is stored on local machines to indicate the user has already received the proactive modal.
export const PROACTIVE_COOKIE_NAME = 'proactiveResolved';
// The name of the CSS class on the "close chat" icon.
export const CHAT_CLASS_CLOSE_ICON = 'disw-live-chat-modal-header-x-button';
// The name of the CSS class on the "open chat" icon.
export const CHAT_CLASS_OPEN_ICON = 'disw-live-chat-icon';
// The full list of classes which can be attributed to the customer interacting with chat. Used to help identify when user clicks off of chat.
export const CHAT_MODAL_DIV = [
    'disw-live-chat-modal-bound-div',
    'disw-live-chat-modal-header',
    'disw-live-chat-modal-header-text',
    'disw-live-chat-option-welcome-text',
    'disw-live-chat-option-help-text',
    'disw-modal-icon',
    'disw-live-chat-modal-welcome-div',
    CHAT_CLASS_CLOSE_ICON,
    'disw-live-chat-option-button',
    'disw-live-chat-option-text',
    'disw-live-chat-options-container',
    'disw-chat-icon-caret',
    'disw-chat-icon-ready'
];
// After testing various zoom levels with window.height and width. These are the values to tune the chat position.
export const CHAT_DISTANCE_FROM_TOP = 710;
export const CHAT_DISTANCE_FROM_LEFT = 410;
// Page to redirect to if an external request is made to open a modal but chat not loaded
export const LIVE_CHAT_NOT_LOADED_CONTACT_PAGE = 'https://resources.sw.siemens.com/en-US/contact-plm-store';
