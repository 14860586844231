export const BASE_CDN_URL = 'https://static.sw.cdn.siemens.com';
export const BASE_CDN_URL_UAT = 'https://static-uat.sw.cdn.siemens.com';
export const BASE_CDN_URL_DEV = 'https://static-dev.sw.cdn.siemens.com';
export const BASE_CDN_URL_STAGE = 'https://static-stage.sw.cdn.siemens.com';
// Base domain for the DISW Middleware (production).
export const BASE_API_CDN_URL = 'https://api.sw.siemens.com';
// Base domain for the DISW Middlware (development) - note we're temporarily using the AWS provided hostname to get around IP restriction which restricts some of our team.
export const BASE_API_CDN_URL_DEV = 'https://21hhjddvkg.execute-api.us-east-2.amazonaws.com';
// Base domain for the DISW middleware (UAT environment)
export const BASE_API_CDN_URL_UAT = 'https://ekdi80rux6.execute-api.us-east-2.amazonaws.com';
// These URLs are for the DISW Middleware
export const MIDDLEWARE_API_URL = `${BASE_API_CDN_URL}/v1`;
export const MIDDLEWARE_API_URL_DEV = `${BASE_API_CDN_URL_DEV}/v1`;
export const MIDDLEWARE_API_URL_UAT = `${BASE_API_CDN_URL_UAT}/v1`;
export const MIDDLEWARE_API_URL_MOCK = 'https://8d5fe0eb-1bbc-4761-8c10-24e86337d7f9.mock.pstmn.io/dex-services-svc';
export const MIDDLEWARE_API_SUPPORT_CONTACTS_URL = `${MIDDLEWARE_API_URL}/livechat/phone-numbers/`;
export const MIDDLEWARE_API_SUPPORT_CONTACTS_URL_DEV = `${MIDDLEWARE_API_URL_DEV}/livechat/phone-numbers/`;
export const MIDDLEWARE_API_SUPPORT_CONTACTS_URL_UAT = `${MIDDLEWARE_API_URL_UAT}/livechat/phone-numbers/`;
// These URLs are specific to the DEX Services API
export const DEX_SERVICES_API_URL = `${MIDDLEWARE_API_URL}/dex-services`;
export const DEX_SERVICES_API_URL_DEV = `${MIDDLEWARE_API_URL_DEV}/dex-services`;
export const DEX_SERVICES_API_URL_UAT = `${MIDDLEWARE_API_URL_UAT}/dex-services`;
export const DEX_SERVICES_API_URL_MOCK = 'https://8d5fe0eb-1bbc-4761-8c10-24e86337d7f9.mock.pstmn.io/dex-services-svc';
export const DEX_CHECKOUT_SERVICE_URL = `${DEX_SERVICES_API_URL}/checkout`;
export const DEX_CHECKOUT_SERVICE_URL_DEV = `${DEX_SERVICES_API_URL_DEV}/checkout`;
export const DEX_CHECKOUT_SERVICE_URL_UAT = `${DEX_SERVICES_API_URL_UAT}/checkout`;
export const WEBKEY_UPDATE_PROFILE_URL = 'https://identity.industrysoftware.automation.siemens.com/WebkeyLogin/DigitalExchangeUpdateProfile';
export const WEBKEY_UPDATE_PROFILE_URL_DEV = 'https://webtest.industrysoftware.automation.siemens.com/WebkeyLogin/DigitalExchangeUpdateProfile';
export const CDN_TRANSLATIONS_URL = `${BASE_CDN_URL}/translations`; // Note: Translations are all on this URL, env passed as part of path
/**
 * Returns the appropriate DISW Middleware / DEX Middleware URL for the given environment.
 *
 * @param {string} env The environment to get the URL for. Can be one of "dev", "uat", or "prod".
 * @param {boolean} dexServices Whether the URL is for DEX services or just the middleware in general
 * @returns {string} The environment specific URL
 */
function _getMiddlewareUrl(env, dexServices = false) {
    if (env === 'prod') {
        return dexServices ? DEX_SERVICES_API_URL : MIDDLEWARE_API_URL;
    }
    else if (env === 'uat') {
        return dexServices ? DEX_SERVICES_API_URL_UAT : MIDDLEWARE_API_URL_UAT;
    }
    else if (env === 'mock') {
        return dexServices ? DEX_SERVICES_API_URL_MOCK : MIDDLEWARE_API_URL_MOCK;
    }
    // default to DEV
    return dexServices ? DEX_SERVICES_API_URL_DEV : MIDDLEWARE_API_URL_DEV;
}
/**
 * Get the DEX Services URL for the specified environment.
 *
 * @param {string} env The environment to get the URL for. Can be one of "dev", "uat", or "prod".
 * @returns The DEX Services Middleware URL for the given environment.
 */
export function getDEXServicesAPIURL(env) {
    return _getMiddlewareUrl(env, true);
}
/**
 * Get the DISW Middleware API URL for the specified environment.
 *
 * @param {string} env The environment to get the URL for. Can be one of "dev", "uat", or "prod".
 * @returns DISW Middleware API URL for the given environment.
 */
export function getMiddlewareAPIURL(env) {
    return _getMiddlewareUrl(env, false);
}
