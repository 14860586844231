import logger from './logger';
import { getMiddlewareAPIURL } from './constants/api_urls';
import { PROD_ENV } from './constants/environments';
/**
 * This is a function to get the current geolocation of the customer.
 *
 * @param {string} env dictates the version of the middleware API to call (dev / uat / prod)
 * @return {Country} Country - An object containing country code and country name
 *
 */
export async function fetchCountry(env = PROD_ENV) {
    // Get the right middleware URL for this environment
    const diswMiddlewareApiURL = getMiddlewareAPIURL(env);
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 10000);
    return fetch(`${diswMiddlewareApiURL}/country/locate`, {
        signal: controller.signal
    })
        .then(response => (response.ok ? response.json() : undefined))
        .catch(error => {
        logger.log(`Error determining country: ${error}`);
        return undefined;
    });
}
/**
 * Fetch the list of countries in Contentful by locale (i.e., 'en-US') and environment (corresponding middleware API).
 *
 * @param {string} localeCode representing the locale for the site (i.e., 'ja-JP')
 * @param {string} env dictates the version of the middleware API to call (dev / uat / prod)
 * @returns JSON with a countries property with keys 'key' & 'value' corresponding to the country name / code respectively.
 *
 *     fetchCountries('en-US', 'env')
 */
export async function fetchCountries(localeCode, env) {
    // Get the right middleware URL for this environment
    const diswMiddlewareApiURL = getMiddlewareAPIURL(env);
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 10000);
    return fetch(`${diswMiddlewareApiURL}/country/${localeCode}`, {
        signal: controller.signal
    })
        .then(response => {
        if (response.ok) {
            return response.json();
        }
        console.error(`Error loading country list. API returned - '${response.status}' - with response text: '${response.statusText}'`);
        throw Error(response.statusText);
    })
        .catch(error => {
        // Error in fetch...throwing
        throw error;
    });
}
