import { subscribe, emit, events } from './eventManager';
import { fetchUserProfileInfo, fetchNotifications, fetchNotificationSummary, dismissNotifications, fetchSession } from './apiUtils';
const DEFAULT_AUTH_ENV = 'prod';
let account = {
    authenticated: false,
    user: undefined,
    profile: undefined,
    notifications: undefined,
    notificationSummary: undefined
};
let jwtAuth = false;
let error = false;
// store the authurl for prod right away incase the initializeAccount function doesn't get ran.
let authenticationUrl = getAuthUrl(DEFAULT_AUTH_ENV);
let extraProfileLinks = [];
export async function initializeAccount(config = {}) {
    const { userRequired = false, customAuthentication = false, redirectUrl = undefined, authEnv = DEFAULT_AUTH_ENV } = config;
    authenticationUrl = getAuthUrl(authEnv);
    extraProfileLinks = Array.isArray(config.extraProfileLinks) ? config.extraProfileLinks : [];
    // set the account variable just incase it got messed up
    initializeAccountVariable();
    if (customAuthentication) {
        jwtAuth = true;
    }
    // If the consuming app is going to handle their own authentication, don't fetch the user.
    if (!customAuthentication) {
        try {
            const { authenticated, sid, token: jwt, user } = await fetchSession({ authenticationUrl });
            if (userRequired && !authenticated) {
                login(redirectUrl);
            }
            account.authenticated = authenticated;
            account.sid = sid;
            if (authenticated) {
                account.user = {
                    ...user,
                    jwt
                };
            }
            setAccount();
        }
        catch (error) {
            setAccount(error);
        }
    }
}
function setAccount(error = false) {
    emit(events.ACCOUNT, account, error);
    // emit event for the depricated subscribeToUser
    emit(events.USER, account.user, error);
}
function setUser(user) {
    account.user = user;
    setAccount();
}
function subscribeToAccount(callback) {
    return subscribe(events.ACCOUNT, callback);
}
function subscribeToUser(callback) {
    return subscribe(events.USER, callback);
}
function loadUserNotifications() {
    var _a;
    return fetchNotifications({ authenticationUrl, jwt: (_a = account.user) === null || _a === void 0 ? void 0 : _a.jwt })
        .then(notifications => {
        account.notifications = notifications;
        error = null;
        emit(events.ACCOUNT, account, error);
    })
        .catch(err => (error = err));
}
function loadUserNotificationSummary() {
    var _a;
    return fetchNotificationSummary({ authenticationUrl, jwt: (_a = account.user) === null || _a === void 0 ? void 0 : _a.jwt })
        .then(notificationSummary => {
        account.notificationSummary = notificationSummary;
        error = null;
        emit(events.ACCOUNT, account, error);
    })
        .catch(err => (error = err));
}
async function dismissUserNotifications() {
    var _a;
    try {
        await dismissNotifications({ authenticationUrl, jwt: (_a = account.user) === null || _a === void 0 ? void 0 : _a.jwt });
        // wrap setTimeout in a promise so we can await it
        await new Promise(resolve => setTimeout(resolve, 1000));
        // Query the notification summary because dismissing the notifications causes
        // a change on the api.
        await loadUserNotificationSummary();
    }
    catch (err) {
        error = err;
    }
}
function loadProfile() {
    var _a;
    fetchUserProfileInfo({ authenticationUrl, jwt: (_a = account.user) === null || _a === void 0 ? void 0 : _a.jwt })
        .then(profile => {
        account.profile = profile;
        error = null;
        emit(events.ACCOUNT, account, error);
    })
        .catch(err => (error = err));
}
function login(redirectUrl = window.location.href) {
    window.location.href = getLoginUrl(redirectUrl);
}
function academicLogin(redirectUrl = window.location.href) {
    window.location.href = getAcademicLoginUrl(redirectUrl);
}
function linkedinLogin(redirectUrl = window.location.href) {
    window.location.href = getLinkedinLoginUrl(redirectUrl);
}
function googleLogin(redirectUrl = window.location.href) {
    window.location.href = getGoogleLoginUrl(redirectUrl);
}
function logout(redirectUrl = window.location.href) {
    window.location.href = getLogoutUrl(redirectUrl);
}
function createAccount(redirectUrl = window.location.href) {
    window.location.href = getCreateAccountUrl(redirectUrl);
}
function getLoginUrl(redirectUrl = window.location.href) {
    return `${authenticationUrl}/login?returnTo=${encodeURIComponent(redirectUrl)}`;
}
function getAcademicLoginUrl(redirectUrl = window.location.href) {
    return `${authenticationUrl}/login/academic?returnTo=${encodeURIComponent(redirectUrl)}`;
}
function getGoogleLoginUrl(redirectUrl = window.location.href) {
    return `${authenticationUrl}/login/google?returnTo=${encodeURIComponent(redirectUrl)}`;
}
function getLinkedinLoginUrl(redirectUrl = window.location.href) {
    return `${authenticationUrl}/login/linkedin?returnTo=${encodeURIComponent(redirectUrl)}`;
}
function getLogoutUrl(redirectUrl = window.location.href) {
    return `${authenticationUrl}/logout?returnTo=${encodeURIComponent(redirectUrl)}`;
}
function getCreateAccountUrl(redirectUrl = window.location.href) {
    return `${authenticationUrl}/login?view=signup&returnTo=${encodeURIComponent(redirectUrl)}`;
}
function getExtraProfileLinks() {
    return extraProfileLinks;
}
function initializeAccountVariable() {
    account = {
        user: undefined,
        profile: undefined,
        notifications: undefined,
        notificationSummary: undefined
    };
}
export function getAuthUrl(authEnv) {
    let authUrl = 'https://auth.sw.siemens.com';
    if (authEnv === 'dev') {
        authUrl = 'https://auth.stage.swi.siemens.com';
    }
    else if (authEnv === 'siemens-dev') {
        authUrl = 'https://auth.sw.siemens-dev.com';
    }
    else if (authEnv === 'prod.swi') {
        authUrl = 'https://auth.swi.siemens.com';
    }
    return authUrl;
}
/**
 * This is a function to return the current account object
 *
 * @return {Object} Account - An object containing account information
 *
 *  @example
 *
 *     getAccount()
 */
export function getAccount() {
    return account;
}
// Put the exports that we want to expose to the module on default
export default {
    subscribeToAccount,
    subscribeToUser,
    setUser,
    loadProfile,
    loadUserNotifications,
    loadUserNotificationSummary,
    dismissUserNotifications,
    login,
    academicLogin,
    linkedinLogin,
    googleLogin,
    logout,
    createAccount,
    getLoginUrl,
    getAcademicLoginUrl,
    getGoogleLoginUrl,
    getLinkedinLoginUrl,
    getLogoutUrl,
    getCreateAccountUrl,
    getAccount,
    getExtraProfileLinks
};
