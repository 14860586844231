// Defaults is for unit testing
export const CAMPAIGN_TRACKING_URL = process.env.CAMPAIGN_TRACKING_URL || 'https://api.dev.swi.siemens.com/ct';

// Defaults is for unit testing
export const CAMPAIGN_TRACKING_API_KEY = process.env.CAMPAIGN_TRACKING_API_KEY || 'CT_PUBLIC_ENDPOINT_API_KEY';

export const STRING_LIMIT = 2048;

export const TRACKED_CAMPAIGNS = [
  'cmpid',
  'elqaid',
  'elqcampaignId',
  'etrc',
  'gclid',
  'sfc',
  'spi',
  'stc',
  'utm_campaign',
  'utm_content',
  'utm_creative_format',
  'utm_id',
  'utm_marketing_tactic',
  'utm_medium',
  'utm_source',
  'utm_source_platform',
  'utm_term'
];

/**
 * Grabs search params, filters by white list, and transforms to obj
 * @returns ParamsObject with approved search params as keyVals
 */
export const getTrackedParams = () => {
  const searchParamsArray = window.location.search.slice(1).split('&');

  return searchParamsArray.reduce((acc, keyVal) => {
    const [key, value] = keyVal.split('=');
    return TRACKED_CAMPAIGNS.includes(key) ? { ...acc, [key]: value } : acc;
  }, {});
};

/**
 * - Pass business logic and security check, then POST data to API
 * - Fetch must be asynchronous
 * - userId can be undefined when user in anon
 * @returns undefined
 */
export const saveCampaignSession = ({ sessionId, userId }) => {
  const paramsObject = getTrackedParams();

  // Exit function if business requirements are not met
  if (!Object.keys(paramsObject).length) {
    return;
  }

  // Guards against arbitrary length entries to ddb table
  if (window.location.href.length > STRING_LIMIT || document.referrer.length > STRING_LIMIT) {
    console.error('ct: href or referrer has exceeded max length threshold');
    return;
  }

  const d = new Date();
  const referrer = document.referrer === '' ? undefined : document.referrer;

  const params = {
    eventTime: d.toISOString(),
    referrer,
    sessionId,
    url: window.location.href,
    userId
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': CAMPAIGN_TRACKING_API_KEY
    },
    body: JSON.stringify(params)
  };

  fetch(CAMPAIGN_TRACKING_URL, options).catch(error => {
    console.error('ct:', error);
  });
};
