import { PROD_ENV } from './constants/environments';
/**
 * Simple logger module which will only log out the console logs for all environments except for production
 * as to not expose them on the public web.
 *
 */
const logger = {
    isValidEnv() {
        try {
            return process.env.UNIVERSAL_COMPONENTS_ENV !== PROD_ENV;
        }
        catch (error) {
            return true;
        }
    },
    log(message, options = '') {
        this.isValidEnv() && console.log(message, options);
    },
    warn(message) {
        this.isValidEnv() && console.warn(message);
    },
    error(message) {
        this.isValidEnv() && console.error(message);
    }
};
export default logger;
