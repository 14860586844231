import { addQueryParameter } from './queryUtils';

/**
 * The Context module is responsible for adding context to links using two approaches:
 *  1. Directly updating items in the DOM via regular expressions. See {@link addContextToLinks}.
 *  2. Returning updated URLs passed to a function which adds context to a single URL. See {@link addContextToUrl}.
 *
 */

/**
 * Represents the pages entry ID in Contentful passed via initialization parameters to the disw-utils module.
 */
let _pageId;

/**
 * Represents the site identifier passed via initialization parameters to the disw-utils module
 *  @example "plm", "eda" etc.
 */
let _currentSite;

/**
 * Represents the slug of the local navigation used in the current page. This is an unique string for each local nav in Contentful.
 *  @example 'nx-mold-designer'
 */
let _slug;

/**
 * This is a function to initialize the context data which will provide the consumer context for the current page which the
 * user is viewing. The initialization should be done via the consumer passing key context properties, which can then be used to generate
 * in-context links etc.
 *
 * @param {String} pageId - Entry id of page from contentful
 * @param {String} site - The current site we are on
 * @param {String} slug - This is the Slug for the local nav in the current site
 *
 */
export async function initializeContextData(config = {}) {
  const { pageId, site, slug } = config;
  _pageId = pageId;
  _currentSite = site;
  _slug = slug;
}

/**
 * Return the entry ID of the page passed via initialization parameters.
 *
 * @returns {String} representing the current pages entry ID. @example 3vOGKsLPzbhErLV2s0qADN.
 */
function getPageId() {
  return _pageId;
}

/**
 * Returns the unique lowercase identifier for the current site passed via initialization parameters.
 *
 * @returns {String} the current site which was set via initialization parameters passed to the disw-utils.
 */
function getCurrentSite() {
  return _currentSite;
}

/**
 * The get function below return the slug of the local nav inside the page we are viewing.
 *
 * @returns {String} slug - slug of the local nav, example teamcenter-local-navigation
 */
function getSlug() {
  return _slug;
}

/**
 * A helper function to create base-64 encoded context string
 * @param {String | Object} data A string or json value
 * @returns A base-64 encoded string
 */
function createContext(data) {
  if (isEmpty(data)) {
    return '';
  }
  return typeof data === 'object' ? str_to_base64(JSON.stringify(data)) : str_to_base64(data);
}

/**
 * A helper function to fetch context from url
 * @param {String} parameterKey A string value representing the key of context parameter in url
 * @returns A Object or String containing decoded context values
 */
function getContext(parameterKey) {
  //fetch context from uri
  const context = getUrlParameter(parameterKey);
  if (isEmpty(context)) {
    return '';
  }
  const attributes = base64_to_str(context);
  try {
    return JSON.parse(attributes);
  } catch (error) {
    //not valid json return a string
    return attributes;
  }
}

/**
 * A helper function to encode string to base64
 * @param {String} str A string value
 * @returns A base64 encoded string
 * Reference: https://developer.mozilla.org/en-US/docs/Glossary/Base64
 */
function str_to_base64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

/**
 * A helper function to decode base64 string
 * @param {String} str A string value representing base64 encoded string
 * @returns A decoded string value
 * Reference: https://developer.mozilla.org/en-US/docs/Glossary/Base64
 */
function base64_to_str(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

/**
 * A helper function to check if given string, array or object is empty
 * @param {String | Object | Array} value A string, object or array value
 * @returns A boolean true or false response
 */
export function isEmpty(value) {
  return (
    value === '' ||
    value === undefined ||
    value === null ||
    value.length === 0 ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
}

/**
 * A helper function to extract value of a query string parameter
 * @param {String} name A string value representing the identifier / query parameter name in the uri
 * @returns A string value of query string name passed
 */
function getUrlParameter(name) {
  if (!window) {
    return;
  }

  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * A helper function that adds context to all resources/webinar links in the page.
 * @param {object} contextData - the data object passed from localnav or breadcrumb.
 * @param {String} queryParamComponentType - the querystring param component type identifier
 */
function addContextToLinks(contextData, queryParamComponentType = 'lnc') {
  const base64Data = createContext(contextData);
  //This regex is used to make sure that url doesn't have any unexpected ending and don't contain any parameter without value.
  const containsQueryString = /(\?{1}[a-z]*=[a-z]*&*)/im;
  const domainTypes = ['resources.sw', 'webinars.sw', 'webinars-', 'trials.sw'];

  for (let domainIndex in domainTypes) {
    document.querySelectorAll(`a[href*="//${domainTypes[domainIndex]}"]`).forEach(resource => {
      if (!resource.search.includes(`${queryParamComponentType}=`)) {
        if (containsQueryString.test(resource.search)) {
          resource.href += `&${queryParamComponentType}=${base64Data}`;
        } else {
          resource.href += `?${queryParamComponentType}=${base64Data}`;
        }
      }
    });
  }
}

/**
 * A helper function that adds context (bc & Inc) to the url passed to the function.
 *
 * @param {String} url - the url to add the context to.
 * @param {Array} queryParamComponentType -  the querystring param component type identifiers.
 * @returns {String} string containing a url with bc & lnc queryParam depending upon the queryParamComponentType
 */
function addContextToUrl(url, queryParamComponentType = ['lnc', 'bc']) {
  const locale = window.disw.locales.getCurrentLocaleCode();
  let contextUrl = url;

  if (queryParamComponentType.includes('bc')) {
    // We need to make sure that pageId, currentSite and locale is not undefined before creating a context and adding it to the url.
    // If any of these three is undefined we will not add anything to the contexturl.

    if (_pageId && _currentSite && locale) {
      const base64BcData = createContext({
        page: _pageId,
        site: _currentSite,
        locale
      });
      contextUrl = addQueryParameter(contextUrl, 'bc', base64BcData);
    }
  }

  if (queryParamComponentType.includes('lnc')) {
    //We need to make sure that slug, title and locale is not undefined before creating a context and adding it to the url.
    //If any of these three is undefined we will not add anyything to the contexturl.
    if (_slug && locale) {
      const base64LncData = createContext({
        slug: _slug,
        locale
      });
      contextUrl = addQueryParameter(contextUrl, 'lnc', base64LncData);
    }
  }

  return contextUrl;
}

export default {
  createContext,
  getContext,
  str_to_base64,
  base64_to_str,
  addContextToLinks,
  addContextToUrl,
  getPageId,
  getCurrentSite,
  getSlug
};
