const players = {};
const eventQueue = {};

/**
 * Store a video player object in the players map. Add any events to it that might be in the event queue. This function
 * can also be used to update players that already exist in the map.
 * @param {string} playerId The id of the player.
 * @param {object} playerObject the plyr.io object to store in the player map.
 */
function setVideoPlayer(playerId, playerObject) {
  // If there are some events waiting in the queue, add them to the player.
  if (eventQueue[playerId]) {
    eventQueue[playerId].forEach(({ event, callback }) => {
      playerObject.on(event, callback);
    });

    // remove the events from the queue since we added them to the video
    delete eventQueue[playerId];
  }
  players[playerId] = playerObject;
}

/**
 * Fetch the player from the player map
 * @param {string} playerId the id of the player to fetch
 * @returns The plyr.io object
 */
function getVideoPlayer(playerId) {
  return players[playerId];
}

/**
 * Remove a video player from the video player map.
 * @param {string} playerId the id of the player to remove
 */
function removeVideoPlayer(playerId) {
  delete players[playerId];
}

/**
 * Programmatically pause a video
 * @param {string} playerId the id of the video player to pause.
 */
function pauseVideo(playerId) {
  const player = getVideoPlayer(playerId);

  if (player) {
    player.pause();
  }
}

/**
 * Programmatically play a video
 * @param {string} playerId the id of the video player to pause.
 */
function playVideo(playerId) {
  const player = getVideoPlayer(playerId);

  if (player) {
    player.play();
  }
}

/**
 * Add an event to a video player. Add the event to a queue if the player doesn't exist yet.
 * @param {string} playerId the id of the player to add the event to
 * @param {string} event the event type
 * @param {function} callback callback function to run when the video player event is emitted.
 */
function addEvent(playerId, event, callback) {
  const player = getVideoPlayer(playerId);

  if (player) {
    player.on(event, callback);
  }
  // If the player doesn't exist yet, add the events to the queue to be added to the player when it gets mounted
  else {
    if (!eventQueue[playerId]) {
      eventQueue[playerId] = [];
    }
    eventQueue[playerId].push({ event, callback });
  }
}

// Put the exports that we want to expose to the module on default
export default {
  setVideoPlayer,
  getVideoPlayer,
  removeVideoPlayer,
  pauseVideo,
  playVideo,
  addEvent,
};
