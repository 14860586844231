import { subscribe, emit, events } from './eventManager';

/**
 * This is a function to to close the modal when the user selects to either keep their current subscription terms OR update them.
 * As indicated by the paramter
 *
 * @param updateSubscriptionTerms {boolean} indicates whether or not we should update the subscription terms
 * @param context {boolean} represents the calling context so that two subscribers can determine if they should handle the event.
 * @param updatedCartItem {ICartItem} | undefined if a user chooses to update terms, this will be an ICartItem that represents the updated cart item; undefined otherwise
 *
 */
function closeModal(updateSubscriptionTerms, context, updatedCartItem) {
  emit(events.SUBSCRIPTION_TERMS_CONFLICT_MODAL, updateSubscriptionTerms, context, updatedCartItem);
}

/**
 * Subscribe to the modal events (user closed the modal, or user hit view cart)
 *
 * @param {callback} callback
 * @return {function} function used to subscribe to the modal events
 *
 * @example
 *     subscribeToModal(callback)
 */
function subscribeToModal(callback) {
  return subscribe(events.SUBSCRIPTION_TERMS_CONFLICT_MODAL, callback);
}

export default {
  closeModal,
  subscribeToModal
};
