/**
 * Data provider for universal breadcrumbs.
 * 
 * Assumes the presence of JSON files published to either the site CDN or static CDN in the format as specified in 
 * tests/breadcrumb-disw-en-US.json. See breadcrumbUtils.fetchBreadcrumbs for details about where files
 * are expected to exist and how they should be named.
 */
import { fetchBreadcrumbs } from './breadcrumbUtils';
import { subscribe, events, emit } from './eventManager';
import { default as localesModule } from './locales';
import logger from './logger';
import { getDecodedQuerystringValue } from './queryUtils';

const DEFAULT_ENV = 'prod';
const OVERRIDE_PARAM = 'bc'; // bc is short for breadcrumb context
let breadcrumbdata = {};
let currentpagebreadcrumbs = [];
let pageoverride = getDecodedQuerystringValue(OVERRIDE_PARAM);

/**
 * This is an internal function to set the state of the breadcrumb data for testing.
 * @param {object} breadcrumbData the breadcrumb data
 */
export function _setBreadcrumbData(breadcrumbData) {
    breadcrumbdata = breadcrumbData;
}

/**
 * This is an internal function to set the state of the breacrumb data for testing.
 * @param {object} data the page override data
 */
export function _setPageOverride(data) {
    pageoverride = data;
}

export async function initializeBreadcrumbs(config = {}) {
    const {
        site,
        locale = localesModule.getCurrentLocaleCode(),
        env = DEFAULT_ENV,
        pages,
        overrides = true
    } = config;
    if (!site && !pages) {
        logger.warn("'site' or 'pages' must be specified to load breadcrumbs");
        return;
    }
    if (pageoverride && overrides) {
        try {
            let overrideContext = JSON.parse(pageoverride);         
            await loadBreadcrumbData(overrideContext.site, locale, env);
            let overrideBreadcrumbs = getPageBreadcrumbs(overrideContext.page);
            appendCurrentPageToBreadcrumbs(pages, overrideBreadcrumbs);
            emit(events.BREADCRUMBS, currentpagebreadcrumbs, false);
        } catch (error) {
            // no overrides
            logger.error(error);
            emit(events.BREADCRUMBS, currentpagebreadcrumbs, error);
        }
    } else {
        if (pages) {
            appendCurrentPageToBreadcrumbs(pages, []);
        }
        await loadBreadcrumbData(site, locale, env);
        emit(events.BREADCRUMBS, [], false); // we emit the empty array to force the client to call getCurrentPageBreadcrumbs since they have to specify the page
    }
}

function subscribeToBreadcrumbs(callback) {
    return subscribe(events.BREADCRUMBS, callback);
}

function appendCurrentPageToBreadcrumbs(pages, overrideBreadcrumbs) {
    if (Array.isArray(pages)) {
        if (overrideBreadcrumbs) {
            overrideBreadcrumbs.push(...pages); // add the specified pages to the end of the breadcrumbs
            currentpagebreadcrumbs = overrideBreadcrumbs;
        }
    } else {
        logger.warn('pages param is not an array; ignoring');
    }   
}

/**
 * Loads breadcrumb data from the static CDN for a given site and local.
 * @example Example data format of breadcrumb file
 * {
 *  "site": "disw",
 *  "pages": [
 *      {
 *          "id": "",
 *          "parent": "",
 *          "url": "",
 *          "name": ""
 *      },
 *      ...    
 *  ]
 * }
 * 
 * @param {string} site the site for which to load breadcrumbs (required)
 * @param {string} locale the locale for which to load breadcrumbs (defaults to en-US)
 * @param {string} env the environment for which to load breadcrumbs (defaults to prod)
 * @async
 */
async function loadBreadcrumbData(site, locale, env) {
    try {    
        breadcrumbdata = await fetchBreadcrumbs(site, locale, env);
    } catch (error) {
        logger.warn('Could not load breadcrumbs', error);
    }
}
/**
 * Get the full breadcrumb data for a specific page. This is an internal recursive function to 
 * get the full data for each page in the hierarchy. It returns an array of each page in the 
 * breadcrumb hierarchy starting with the requested page. In other words, the deepest page
 * will be first, and the home page will be last.
 * 
 * @param {string} pageId the unique ID for the page (e.g. for Contentful pages, the Entry ID)
 */
function getPageBreadcrumbsFull(pageId) {
    if (!pageId) { return undefined; }
    let breadcrumbs = [];
    const targetpage = getPage(pageId);
    if (!targetpage) { return undefined; }
    breadcrumbs.push(targetpage);
    const parentBreadcrumb = getPageBreadcrumbsFull(targetpage.parent);
    if (parentBreadcrumb) {
        breadcrumbs.push(...parentBreadcrumb);
    }
    return breadcrumbs;
}

/**
 * Get the breadcrumb data for a specific page as an array starting with the first page in the
 * breadcrumb trail and ending with the last. Each object in the array has two properties: 
 * label and url. label contains the display value for the breadcrumb and url contains the 
 * link value.
 * 
 * @param {string} pageId the unique ID for the page (e.g. for Contentful pages, the Entry ID)
 * @returns {{url: string, label, string}[]} Array of breadcrumbs in order from top to bottom
 */
function getPageBreadcrumbs(pageId) {
    const breadcrumbs = getPageBreadcrumbsFull(pageId);
    if (!breadcrumbs) return [];
    return breadcrumbs.reverse().map(page => {
        return {
            url: page.url,
            label: page.name
        }
    });
}

/**
 * Get the breadcrumbs for the current page based on the override context. If the optional
 * page parameter is specified, get breadcrumbs for that page. This is used in cases
 * where no override context is provided and the breadcrumbs for the page are published.
 * 
 * @param {string} page the id of the current page (optional)
 * @returns {Array} the breadcrumbs for the current page or empty array
 */
function getCurrentPageBreadcrumbs(page=undefined) {
    if (page) {
        const breadcrumbs = getPageBreadcrumbs(page);
        breadcrumbs.push(...currentpagebreadcrumbs);
        const uniqueBreadcrumbs = [... new Set(breadcrumbs.map(JSON.stringify))].map(JSON.parse);
        return uniqueBreadcrumbs;
    }
    return currentpagebreadcrumbs;
}

function getPage(pageId) {
    if (!pageId) { return undefined; }
    return breadcrumbdata && breadcrumbdata.pages && breadcrumbdata.pages.find(page => page.id == pageId);
}

export default {
    getPage,
    getPageBreadcrumbs,
    getCurrentPageBreadcrumbs,
    subscribeToBreadcrumbs
};